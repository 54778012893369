import { TranslationMap } from '../../i18n/translation-types';

export function tabHostTranslations() {
  return {
    mapView: {
      tooltip: {
        text: $localize`:@@toolbar.map-view.tooltip:Map View`,
        desc: "Tooltip for the 'Map View' button.",
      },
    },
    listView: {
      tooltip: {
        text: $localize`:@@toolbar.list-view.tooltip:List View`,
        desc: 'Tooltip for the List View button.',
      },
    },
    view3d: {
      tooltip: {
        text: $localize`:@@toolbar.view-3d.tooltip:3D View`,
        desc: 'Tooltip for the 3D View button.',
      },
      button: {
        text: $localize`:@@toolbar.view-3d.button:3D`,
        desc: 'Text for the 3D View button.',
        limit: 4,
      },
    },
    search: {
      placeholder: {
        text: $localize`:@@toolbar.search.placeholder:Find`,
        desc: 'Placeholder text for the search input.',
      },
    },
    filter: {
      tooltip: {
        text: $localize`:@@toolbar.filter.tooltip:Filter`,
        desc: "Tooltip for the 'Filter' button.",
      },
    },
    download: {
      tooltip: {
        text: $localize`:@@toolbar.download.tooltip:Download Status`,
        desc: "Tooltip for the 'Download Status' button.",
      },
    },
    reload: {
      tooltip: {
        text: $localize`:@@toolbar.reload.tooltip:Reload Project Data`,
        desc: "Tooltip for the 'Reload' button.",
      },
    },
    settings: {
      tooltip: {
        text: $localize`:@@toolbar.settings.tooltip:Settings`,
        desc: "Tooltip for the 'Settings' button.",
      },
    },
    upload: {
      button: {
        text: $localize`:@@toolbar.upload.button:Upload`,
        desc: "Text for the 'Upload' button.",
      },
    },
  } satisfies TranslationMap;
}
