import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Coordinate, Vector3, isDefined, isNil } from '@trimble-gcs/common';
import {
  EMPTY,
  Observable,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  fromEvent,
  map,
  pairwise,
  switchMap,
  take,
  withLatestFrom,
} from 'rxjs';
import {
  Camera,
  CameraMode,
  IColor,
  PanoramaMetadata,
  PointColorType,
  PointIcon,
  PointShape,
  ProjectionType,
  ViewAction,
} from 'trimble-connect-workspace-api';
import { AppState } from '../app-state/app.state';
import {
  ClassificationScheme,
  DEFAULT_CLASSIFICATION_COLOR,
} from '../classification/classification-scheme.model';
import { ConnectWorkspace } from '../connect/connect.models';
import { ConnectService } from '../connect/connect.service';
import { Connect3dCamera } from '../connect/models/connect-3d-camera';
import { injectLogger } from '../logging/logger-injection';
import { Scan3dStyle } from '../scan-3d-panel/models/scan-3d-style';
import { Scan3dPanelComponent } from '../scan-3d-panel/scan-3d-panel.component';
import { CloseSortMenu, CloseTreeMenu } from '../scan-3d-panel/scan-3d.actions';
import { Scan3dService } from '../scan-3d-panel/scan-3d.service';
import {
  PatchScandataModels,
  SelectOnly,
  SetScandata,
  SetSortInfo,
} from '../scandata/scandata.actions';
import {
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { ScandataState } from '../scandata/scandata.state';
import { ClearCurrentStation, SetCurrentStation } from '../station/station.actions';
import {
  CurrentStation,
  Station,
  StationDisplayStatus,
  StationStatus,
} from '../station/station.models';
import { StationState } from '../station/station.state';
import { TilesetFormat, TilesetStatus } from '../tileset/tileset.models';
import { colorHexToArray } from '../utils/color-converter';
import { SetCameraProjection } from './camera/camera.action';
import { CameraProjection } from './camera/camera.models';
import { CameraState } from './camera/camera.state';
import { Host3dViewService } from './host-3d-view.service';
import { ClearHost3dScandata, FitToView } from './host-3d.actions';
import { Host3dService } from './host-3d.service';
import { Host3dState } from './host-3d.state';

export interface PointIconExt extends PointIcon {
  // Not defined in current workspace api, but present in event.
  // Connect uses an InternalPointIcon which extends the interface in the Workspace API.
  // This interface is however not exported.
  // This was communicated to Connect for a solution.
  providedId: number;
}

@UntilDestroy()
@Component({
  selector: 'sd-host-3d',
  standalone: true,
  imports: [Scan3dPanelComponent],
  templateUrl: './host-3d.component.html',
  styleUrls: ['./host-3d.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Host3dComponent implements OnInit {
  private scandata$ = this.store.select(ScandataState.scandata);
  private currentStation$ = this.store.select(StationState.currentStation);
  private cameraProjection$ = this.store.select(CameraState.projection);

  globalStyle = toSignal(this.scan3dService.getGlobalStyle());

  private globalStyle$ = toObservable(this.globalStyle).pipe(filter(isDefined));

  private workspace!: ConnectWorkspace;
  private shouldRequestFocus = false;
  private singleSelectedScan?: ScandataModel;
  private fitToViewEnabled = true;
  private connnect3dCamera: Connect3dCamera = {};

  private logger = injectLogger('Host3dComponent');

  constructor(
    private connectService: ConnectService,
    private scandataService: ScandataService,
    private host3dService: Host3dService,
    private scan3dService: Scan3dService,
    private host3dViewService: Host3dViewService,
    private store: Store,
  ) {
    // Set default 3D sort order
    this.store.dispatch(new SetSortInfo({ sortBy: 'name', sortDirection: 'asc' }));

    const scandata = this.store.selectSnapshot(Host3dState.scandata);
    if (scandata) {
      this.store.dispatch(new SetScandata(scandata));
      this.store.dispatch(new ClearHost3dScandata());
      this.shouldRequestFocus = true;

      const selectedScans = scandata.filter((x) => x.selected);
      if (selectedScans.length === 1) {
        this.singleSelectedScan = selectedScans[0];
      }
    }

    this.selectScandataFromQueryParams();
  }

  async ngOnInit(): Promise<void> {
    this.workspace = await this.connectService.getWorkspace();

    await this.requestFocus();
    this.showSingleSelectedScan();

    this.subscribeToHideScans();
    this.subscribeToDisplayScans();
    this.subscribeToStyleScans();
    this.subscribeToAddStationIcons();
    this.subscribeToRemoveStationIcons();
    this.subscribeToCurrentStation();
    this.subscribeToIconPick();
    this.subscribeToEventsToCloseMenu();
    this.subscribeToCloseAndReset();
    this.subscribeToViewEvent();
    this.subscribeToWindowBlur();
    this.displayViewAfterRefresh();

    await this.setInitialCameraState();
    this.updateCameraProjectionState();
    this.exitStationForCameraPosition();
    this.exitStationForOrthographic();
  }

  private subscribeToHideScans() {
    this.scandata$
      .pipe(
        map((scans) => scans.filter((x) => x.displayStatus === ScandataDisplayStatus.AwaitingHide)),
        distinctUntilChanged((prev, curr) => this.scansChanged(prev, curr)),
        filter((scans) => scans.length > 0),
        untilDestroyed(this),
      )
      .subscribe((scans) => this.unloadModels(scans));
  }

  private subscribeToDisplayScans() {
    this.scandata$
      .pipe(
        map((scans) =>
          scans.filter(
            (x) =>
              x.showInScene &&
              x.loadStatus === ScandataLoadStatus.Loaded &&
              x.displayStatus === ScandataDisplayStatus.Hidden,
          ),
        ),
        distinctUntilChanged((prev, curr) => this.scansChanged(prev, curr)),
        filter((scans) => scans.length > 0),
        withLatestFrom(this.currentStation$),
        untilDestroyed(this),
      )
      .subscribe(([scandata, currentStation]) => this.displayScans(scandata, currentStation));
  }

  private subscribeToStyleScans() {
    combineLatest([this.scandata$, this.globalStyle$])
      .pipe(untilDestroyed(this))
      .subscribe(([scandata, globalStyle]) => this.updateModelStyling(scandata, globalStyle));
  }

  private subscribeToAddStationIcons() {
    combineLatest([this.scandata$, this.cameraProjection$])
      .pipe(
        map(([scans, cameraProjection]) =>
          scans.filter(
            (scan) =>
              isDefined(scan.stations) &&
              scan.stations.length > 0 &&
              scan.displayStatus === ScandataDisplayStatus.Displayed &&
              cameraProjection === CameraProjection.Perspective &&
              !scan.stationIconsDisplayed,
          ),
        ),
        distinctUntilChanged((prev, curr) => this.scansChanged(prev, curr)),
        filter((scans) => scans.length > 0),
        withLatestFrom(this.currentStation$),
        untilDestroyed(this),
      )
      .subscribe(([scans, currentStation]) =>
        this.addStationIconsForDisplayedModels(scans, currentStation?.station),
      );
  }

  private subscribeToRemoveStationIcons() {
    combineLatest([this.scandata$, this.cameraProjection$])
      .pipe(
        map(([scans, cameraProjection]) =>
          scans.filter(
            (scan) =>
              isDefined(scan.stations) &&
              scan.stations.length > 0 &&
              (scan.displayStatus !== ScandataDisplayStatus.Displayed ||
                cameraProjection === CameraProjection.Orthographic) &&
              scan.stationIconsDisplayed,
          ),
        ),
        filter((scans) => scans.length > 0),
        untilDestroyed(this),
      )
      .subscribe((scans) => this.removeStationIconsForModels(scans));
  }

  private async displayScans(scans: ScandataModel[], currentStation?: CurrentStation) {
    const models = await this.loadModels(scans);

    // Restoring a Connect View sets the camera so don't dispatch fitToView.
    if (!currentStation && this.fitToViewEnabled) {
      const displayedScans = models.filter(
        (x) => x.displayStatus === ScandataDisplayStatus.Displayed,
      );

      const scansForFitToView = scans.filter((x) => displayedScans.some((m) => m.id === x.id));

      if (scansForFitToView.length > 0) this.store.dispatch(new FitToView(scansForFitToView));
    } else if (!this.fitToViewEnabled) {
      this.fitToViewEnabled = true;
    }
  }

  private async loadModels(models: ScandataModel[]) {
    this.scandataService.setDisplayStatus(models, ScandataDisplayStatus.AwaitingDisplay);

    const patchModels: Partial<ScandataModel>[] = [];
    for (const model of models) {
      const displayStatus = (await this.loadTilesetsForModel(model))
        ? ScandataDisplayStatus.Displayed
        : ScandataDisplayStatus.DisplayError;

      patchModels.push({
        id: model.id,
        displayStatus,
      });
    }

    this.store.dispatch(new PatchScandataModels(patchModels));

    return patchModels;
  }

  private async unloadModels(models: ScandataModel[]) {
    try {
      const pointClouds = await this.workspace.api.viewer.getPointClouds();
      const foundModels = models.filter((model) => pointClouds.some((x) => x.id === model.web3dId));

      const removed = foundModels.filter(
        async (model) => await this.removeModelFromView(model.web3dId, model.id),
      );

      this.scandataService.setDisplayStatus(removed, ScandataDisplayStatus.Hidden);
    } catch (error: any) {
      this.logger.error(`Unloading models failed`, {}, error);
    }
  }

  private async loadTilesetsForModel(model: ScandataModel): Promise<boolean> {
    const readyPotreeTileset = model.tilesets?.find(
      (x) => x.status === TilesetStatus.Ready && x.format === TilesetFormat.Potree,
    );

    if (isNil(readyPotreeTileset) || isNil(readyPotreeTileset.rootUrl)) return false;

    try {
      return await this.workspace.api.viewer.addPointCloud({
        id: model.web3dId,
        url: readyPotreeTileset.rootUrl,
      });
    } catch (error: any) {
      this.logger.error(`Point cloud ${model.id} failed to load`, {}, error);
      return false;
    }
  }

  private subscribeToCurrentStation() {
    this.currentStation$
      .pipe(pairwise(), withLatestFrom(this.cameraProjection$), untilDestroyed(this))
      .subscribe(async ([[previous, current], cameraProjection]) => {
        if (previous && (isNil(current) || previous.station.id !== current.station.id)) {
          const cameraMode = this.connnect3dCamera.cameraMode ?? CameraMode.Rotate;
          try {
            await this.workspace.api.viewer.setCameraMode(cameraMode);
          } catch (error: any) {
            this.logger.error(`Failed to set camera mode ${cameraMode}`, {}, error);
          }

          await this.enablePickingTool();
          await this.unloadStation(previous.station);

          const scan = this.store.selectSnapshot(
            ScandataState.getScan(previous.station.pointcloudId),
          );

          if (
            (scan?.displayStatus === ScandataDisplayStatus.Displayed ||
              scan?.displayStatus === ScandataDisplayStatus.AwaitingDisplay) &&
            cameraProjection === CameraProjection.Perspective
          ) {
            await this.addStationIcon(previous.station);
          }
        }

        if (previous && !current) {
          await this.setCameraAfterStationExit(previous.station);
        }

        if (current) {
          await this.loadCurrentStation(current);
          await this.removeStationIcon(current.station);
        }
      });
  }

  private async loadCurrentStation(current: CurrentStation) {
    if (current.displayStatus === StationDisplayStatus.AwaitingDisplay) {
      const added = await this.addStationToView(current.station);
      const cameraSet = added && (await this.setCameraForStation(current.station));
      const loaded = added && cameraSet;

      if (added && !cameraSet)
        await this.removeModelFromView(current.station.web3dId, current.station.id);

      this.store.dispatch(
        new SetCurrentStation({
          station: current.station,
          displayStatus: loaded
            ? StationDisplayStatus.Displayed
            : StationDisplayStatus.DisplayError,
        }),
      );
    }
  }

  private async addStationToView(station: Station) {
    // Returning true here for location only stations
    if (isNil(station.tilesetInfo?.tilesetUrl)) return true;

    const urlParts = station.tilesetInfo.tilesetUrl.split('?');
    const tilesUrl = urlParts[0] + '/' + station.tilesetInfo.tileTemplate + '?' + urlParts[1];

    // Legacy db entries - Previously tileSize was hard-coded as 512
    const tileHeight = station.tilesetInfo.tileHeight > 0 ? station.tilesetInfo.tileHeight : 512;

    const metadata: PanoramaMetadata = {
      id: station.web3dId,
      headingInDegrees: station.orientation?.yaw ?? 0,
      pitchInDegrees: station.orientation?.pitch ?? 0,
      rollInDegrees: station.orientation?.roll ?? 0,
      eulerOrder: station.orientation?.rotationOrder ?? 'XYZ',
      tilesUrl: tilesUrl,
      tileHeight: tileHeight,
      isLowerLeftOrigin: false,
      maxWidth: station.tilesetInfo.resizedWidth,
      maxHeight: station.tilesetInfo.resizedHeight,
      sphereRadius: 5,
      position: { x: station.position.x, y: station.position.y, z: station.position.z },
      fovTop: 90,
      fovBottom: -90,
      fovLeft: -180,
      fovRight: 180,
      minLodLevel: 3,
      lodAdjustment: 1,
    };

    return await this.workspace.api.viewer.addPanorama(metadata).catch((err) => {
      this.logger.error(`Adding station ${station.id} failed`, {}, err);
      return false;
    });
  }

  private async removeModelFromView(web3dId: string, objectId: string) {
    try {
      return await this.workspace.api.viewer.removeModel(web3dId);
    } catch (error: any) {
      this.logger.error(`Removing web3d model ${objectId} failed`, {}, error);
      return false;
    }
  }

  private async setCameraAfterStationExit(station: Station) {
    try {
      const camera = await this.workspace.api.viewer.getCamera();
      camera.fieldOfView = this.connnect3dCamera.fieldOfView;

      this.connnect3dCamera = {};

      await this.connectService.setCamera({ ...camera });
    } catch (error: any) {
      this.logger.error(`Setting camera after station ${station.id} exit failed`, {}, error);
    }
  }

  private async setCameraForStation(station: Station) {
    try {
      const camera = await this.workspace.api.viewer.getCamera();
      const cameraMode = await this.workspace.api.viewer.getCameraMode();
      const fieldOfView = this.connnect3dCamera.fieldOfView ?? camera.fieldOfView;

      this.connnect3dCamera = { ...camera, fieldOfView, cameraMode };

      await this.connectService.setCamera({
        ...camera,
        position: station.position,
      });

      return await this.workspace.api.viewer.setCameraMode(CameraMode.Panorama);
    } catch (error: any) {
      this.logger.error(`Failed to set camera for station ${station.id}`, {}, error);
      return false;
    } finally {
      await this.enablePickingTool();
    }
  }

  private async unloadStation(station: Station) {
    // Connect currently bundles stations and point clouds in the same result

    try {
      const pointClouds = await this.workspace.api.viewer.getPointClouds();

      if (pointClouds.find((x) => x.id === station.web3dId)) {
        await this.removeModelFromView(station.web3dId, station.id);
      }
    } catch (error: any) {
      this.logger.error(`Unloading station ${station.id} failed`, {}, error);
    }
  }

  private getStationsForModels(models: ScandataModel[]): Station[] {
    return models.flatMap((model) => model.stations ?? []);
  }

  private addStationIconsForDisplayedModels(scans: ScandataModel[], currentStation?: Station) {
    let stations = this.getStationsForModels(scans).filter((x) => x.status === StationStatus.Ready);

    // Remove current station from list to prevent clicking on it
    if (currentStation) {
      stations = stations.filter((x) => x.id !== currentStation.id);
    }

    if (stations.length === 0) return;

    // Not awaiting so we can patch state faster.
    // viewer.removeIcon doesn't return a success or failure atm
    stations.forEach((station) => {
      this.addStationIcon(station);
    });

    this.store.dispatch(
      new PatchScandataModels(
        scans.map((m) => ({
          id: m.id,
          stationIconsDisplayed: true,
        })),
      ),
    );
  }

  private async addStationIcon(station: Station) {
    try {
      const icon = this.mapStationToPointIcon(station);
      await this.workspace.api.viewer.addIcon(icon);
      return true;
    } catch (error: any) {
      this.logger.error(`Add icon for station ${station.id} failed`, {}, error);
      return false;
    }
  }

  private removeStationIconsForModels(scans: ScandataModel[]) {
    const stations = this.getStationsForModels(scans).filter(
      (x) => x.status === StationStatus.Ready,
    );

    if (stations.length === 0) return;

    // Not awaiting so we can patch state faster.
    // viewer.removeIcon doesn't return a success or failure atm
    stations.forEach((station) => {
      this.removeStationIcon(station);
    });

    this.store.dispatch(
      new PatchScandataModels(
        scans.map((m) => ({
          id: m.id,
          stationIconsDisplayed: false,
        })),
      ),
    );
  }

  private async removeStationIcon(station: Station) {
    try {
      const icon = this.mapStationToPointIcon(station);
      await this.workspace.api.viewer.removeIcon(icon);
      return true;
    } catch (error: any) {
      this.logger.error(`Remove icon for station ${station.id} failed`, {}, error);
      return false;
    }
  }

  private mapStationToPointIcon(station: Station): PointIcon {
    const baseUrl = this.store.selectSnapshot(AppState.settings).baseUrl;

    return {
      id: station.iconId,
      iconPath: `${baseUrl}/assets/station.png`,
      position: { x: station.position.x, y: station.position.y, z: station.position.z },
      size: 40,
    };
  }

  private subscribeToIconPick() {
    const stations$ = this.scandata$.pipe(map((models) => this.getStationsForModels(models)));

    this.workspace.event$
      .pipe(
        filter((event) => event.id === 'viewer.onIconPicked'),
        map((event) => event.data as PointIconExt[]),
        filter((icons) => icons && icons.length > 0),
        withLatestFrom(this.currentStation$),
        filter(([icons, currentStation]) => currentStation?.station.iconId !== icons[0].providedId),
        withLatestFrom(stations$),
        map(([[icons], stations]) => stations.find((s) => s.iconId === icons[0].providedId)),
        filter((station): station is Station => isDefined(station)),
        switchMap((station) => this.host3dService.setCurrentStation(station)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private subscribeToEventsToCloseMenu() {
    this.workspace.event$
      .pipe(
        filter(
          (event) =>
            event.id === 'viewer.onPicked' ||
            event.id === 'viewer.onIconPicked' ||
            event.id === 'viewer.onCameraChanged',
        ),
        untilDestroyed(this),
      )
      .subscribe(() => this.store.dispatch([new CloseTreeMenu(), new CloseSortMenu()]));
  }

  private subscribeToCloseAndReset() {
    this.workspace.event$
      .pipe(
        filter((event) => event.id === 'extension.closing' || event.id === 'viewer.onModelReset'),
        withLatestFrom(this.scandata$, this.currentStation$),
        untilDestroyed(this),
      )
      .subscribe(([, scandata, currentStation]) => {
        const actions = [];

        const modelsToHide = scandata.filter(
          (model) =>
            model.displayStatus &&
            ![ScandataDisplayStatus.Hidden, ScandataDisplayStatus.AwaitingHide].includes(
              model.displayStatus,
            ),
        );

        if (modelsToHide.length > 0) {
          actions.push(
            new PatchScandataModels(
              modelsToHide.map((x) => ({
                id: x.id,
                displayStatus: ScandataDisplayStatus.AwaitingHide,
                showInScene: false,
              })),
            ),
          );
        }

        if (isDefined(currentStation)) {
          actions.push(new ClearCurrentStation());
        }

        if (actions.length > 0) {
          this.store.dispatch(actions);
        }
      });
  }

  private updateModelStyling(scandata: ScandataModel[], globalStyle: Scan3dStyle) {
    const models = scandata.filter((s) => s.displayStatus === ScandataDisplayStatus.Displayed);

    models.forEach((model) => {
      const style = model.scan3dStyle ?? globalStyle;
      const { colors, visibleArray } = this.getClassificationSettings(
        style.classificationSchemes,
        model,
      );

      this.workspace.api.viewer
        .setPointCloudSettings(
          {
            classificationColors: colors,
            classificationVisibility: visibleArray,
            pointColorBy: this.getPointColorByForStyle(style),
            pointSize: style.pointSize,
            densityBias: style.pointDensity,
            pointBudget: style.pointBudget,
            pointShape: PointShape.PARABOLOID,
            sizeAttenuation: false,
            shading: style.showEyeDomeLighting ? 'EYE_DOME_LIGHTING' : 'DEFAULT',
            edlRadius: 1.4,
            edlStrength: 0.7,
            intensityGradient: this.getIntensityGradient(style),
          },
          [model.web3dId],
        )
        .catch((err) => {
          this.logger.error(`Set point cloud settings ${model.id} failed`, {}, err);
          return false;
        });
    });
  }

  private getPointColorByForStyle(style: Scan3dStyle) {
    if (style.showClassification && style.showIntensity)
      return PointColorType.INTENSITY_CLASSIFICATION;
    if (style.showClassification) return PointColorType.CLASSIFICATION;
    if (style.showIntensity) return PointColorType.INTENSITY;
    return PointColorType.RGB;
  }

  private getIntensityGradient(style: Scan3dStyle): [number, IColor][] | undefined {
    if (!style.showIntensity) return undefined;

    const startColor = colorHexToArray(style.intensityStartColor) as number[];
    const endColor = colorHexToArray(style.intensityEndColor) as number[];

    return [
      [0, { r: startColor[0], g: startColor[1], b: startColor[2] }],
      [style.intensityOffset, { r: endColor[0], g: endColor[1], b: endColor[2] }],
    ];
  }

  private getClassificationSettings(schemes: ClassificationScheme[], scan: ScandataModel) {
    const rgbHexArray = Array<string>(256).fill(DEFAULT_CLASSIFICATION_COLOR);
    const visibleArray = Array<boolean>(256).fill(true);

    schemes.forEach((scheme) => {
      rgbHexArray[scheme.id] = scheme.rgba;
      visibleArray[scheme.id] = scheme.visible;
    });

    scan.scan3dStyle?.classificationSchemes.forEach((scheme) => {
      rgbHexArray[scheme.id] = scheme.rgba;
      visibleArray[scheme.id] = scheme.visible;
    });

    const colors: IColor[] = rgbHexArray
      .map((rgb) => colorHexToArray(rgb) ?? [0, 0, 0])
      .map((s) => ({ r: s[0], g: s[1], b: s[2] }));

    return { colors, visibleArray };
  }

  private subscribeToViewEvent() {
    this.workspace.event$
      .pipe(
        filter((event) => event.id === 'view.onViewAction'),
        map((event) => event.data as ViewAction),
        switchMap((viewAction) =>
          this.getViewAction$(viewAction).pipe(
            take(1),
            catchError((err) => {
              this.logger.error(`View action '${viewAction.action}' failed`, {}, err);
              return EMPTY;
            }),
          ),
        ),
      )
      .subscribe();
  }

  // View errors are caught to keep subscription alive
  private getViewAction$(viewAction: ViewAction): Observable<unknown> {
    switch (viewAction.action) {
      case 'created':
        return this.host3dViewService.createView(viewAction.view);
      case 'updated':
        return this.host3dViewService.updateView(viewAction.view);
      case 'removed':
        return this.host3dViewService.removeView(viewAction.view);
      case 'set': {
        this.fitToViewEnabled = false;
        return this.host3dViewService.setView(viewAction.view);
      }
    }
  }

  private subscribeToWindowBlur() {
    fromEvent(window, 'blur')
      .pipe(untilDestroyed(this))
      .subscribe(() => this.store.dispatch([new CloseTreeMenu(), new CloseSortMenu()]));
  }

  private scansChanged(prev: ScandataModel[], curr: ScandataModel[]) {
    return (
      isDefined(prev) &&
      prev.every((x) => isDefined(curr.find((c) => c.id === x.id))) &&
      curr.every((x) => isDefined(prev.find((p) => p.id === x.id)))
    );
  }

  private async displayViewAfterRefresh() {
    const currentView = await this.workspace.api.view.getCurrentView();
    if (isNil(currentView)) return;

    this.fitToViewEnabled = false;

    this.scandata$
      .pipe(
        filter((scans) => scans.length > 0),
        take(1), // setView updates scandata state which causes circular emissions
        switchMap(() => this.host3dViewService.setView(currentView)),
      )
      .subscribe({
        error: (err) => this.logger.error(`Load View after refresh failed`, {}, err),
      });
  }

  private async setInitialCameraState() {
    from(this.workspace.api.viewer.getCamera())
      .pipe(
        filter(isDefined),
        switchMap((camera) =>
          this.store.dispatch(
            new SetCameraProjection(this.mapCameraProjectionType(camera.projectionType)),
          ),
        ),
      )
      .subscribe({
        error: (err) => this.logger.error(`Set inital camera state failed`, {}, err),
      });
  }

  private updateCameraProjectionState() {
    this.observeCameraChangeEvent()
      .pipe(
        switchMap((camera) =>
          this.store.dispatch(
            new SetCameraProjection(this.mapCameraProjectionType(camera.projectionType)),
          ),
        ),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private exitStationForCameraPosition() {
    // Changing to a different Connect camera view point should exit the station
    this.observeCameraChangeEvent()
      .pipe(
        map((camera) => camera.position),
        filter((position): position is Vector3 => isDefined(position)),
        withLatestFrom(this.currentStation$),
        filter(
          ([, currentStation]) =>
            isDefined(currentStation) &&
            currentStation.displayStatus === StationDisplayStatus.Displayed,
        ),
        map(([position, currentStation]) => [
          position,
          currentStation?.station.position as Coordinate,
        ]),
        map((coordinates) => coordinates.map((c) => Vector3.fromCoordinate(c))),
        filter(([cameraPosition, stationPosition]) => !cameraPosition.match(stationPosition, 1e-6)),
        switchMap(() => this.store.dispatch(new ClearCurrentStation())),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private exitStationForOrthographic() {
    this.observeCameraChangeEvent()
      .pipe(
        map((camera) => this.mapCameraProjectionType(camera.projectionType)),
        withLatestFrom(this.currentStation$),
        filter(
          ([projection, currentStation]) =>
            projection === CameraProjection.Orthographic && isDefined(currentStation),
        ),
        switchMap(() => this.store.dispatch(new ClearCurrentStation())),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private observeCameraChangeEvent() {
    return this.workspace.event$.pipe(
      filter((event) => event.id === 'viewer.onCameraChanged'),
      map((event) => event.data as Camera),
    );
  }

  private mapCameraProjectionType(projectionType?: ProjectionType) {
    switch (projectionType) {
      case 'ortho':
        return CameraProjection.Orthographic;
      case 'perspective':
        return CameraProjection.Perspective;
      default:
        return undefined;
    }
  }

  private selectScandataFromQueryParams() {
    this.scandata$
      .pipe(
        filter((scans) => scans.length > 0),
        take(1),
        withLatestFrom(this.store.selectOnce(AppState.urlQueryScandataIds)),
        filter(([, scandataIds]) => scandataIds.length > 0),
        switchMap(([scans, scandataIds]) =>
          this.store.dispatch(new SelectOnly(scandataIds)).pipe(map(() => scans)),
        ),
        map((scans) => scans.filter((x) => x.selected)),
        filter((selected) => selected.length === 1),
        switchMap((selected) => this.host3dService.showScan(selected[0])),
        take(1),
      )
      .subscribe();
  }

  private async requestFocus() {
    const hasParams = this.store.selectSnapshot(AppState.hasUrlQueryParams);

    if (this.shouldRequestFocus || hasParams) {
      await this.workspace.api.extension.requestFocus().catch(() => false);
    }
  }

  private showSingleSelectedScan() {
    if (isDefined(this.singleSelectedScan) && this.singleSelectedScan.pointCount > 0) {
      this.host3dService.showScan(this.singleSelectedScan).pipe(take(1)).subscribe();
    }
  }

  private async enablePickingTool() {
    try {
      // Workaround suggested by Connect.  Web3d update now disables picking tool
      // for some of the calls made during setCameraMode in Connect.
      await this.workspace.api.viewer.activateTool('reset');
    } catch (error: any) {
      this.logger.error(`Failed to enable picking tool`, {}, error);
    }
  }
}
