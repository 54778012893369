export enum ConnectTreeItemType {
  previous = 'previous',
  folder = 'folder',
  file = 'file',
}

export interface ConnectTreeItem {
  type: ConnectTreeItemType;
  regionUrl: string;
  versionId: string;
  id: string;
  name: string;
  size: number;
  modifiedBy: string;
  modifiedOn: Date;
  selected: boolean;
}

export interface ConnectFileDownload {
  id: string;
  versionId: string;
  url: string;
}
