import { DOCUMENT, NgLocaleLocalization } from '@angular/common';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { clearTranslations, loadTranslations, MessageId, TargetMessage } from '@angular/localize';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { getLangDir } from 'rtl-detect';
import { firstValueFrom } from 'rxjs';
import { ConnectService } from '../connect/connect.service';
import { injectLogger } from '../logging/logger-injection';
import { LocaleLoader } from './locale-loader';
import { defaultLocale, getLocaleInfo, LocaleId } from './locale-support';
import { SetLocale } from './locale.actions';
import { LocaleState } from './locale.state';
import { TranslationLoader } from './translation-loader';
import { PluralMap, SelectMap } from './translation-types';

const INTERPOLATION_REGEXP: RegExp = /\{(\w+)\}/g;

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  private connectService = inject(ConnectService);
  private store = inject(Store);
  private localeLoader = inject(LocaleLoader);
  private translationLoader = inject(TranslationLoader);
  private document = inject(DOCUMENT);
  private logger = injectLogger('TranslationService');

  get currentLocale(): LocaleId {
    return this.store.selectSnapshot(LocaleState.getLocale) ?? defaultLocale;
  }

  async initLocale(): Promise<void> {
    let locale: string | undefined;
    const ws = await this.connectService.getWorkspace();

    if (isDefined(ws)) {
      const user = await ws.api.user.getUser();
      locale = user?.language;
    } else {
      locale = 'af-ZA';
    }

    return await this.setLocale(locale ?? defaultLocale);
  }

  async setLocale(locale: string): Promise<void> {
    try {
      const { localeId, ngLocale } = getLocaleInfo(locale);

      if (localeId === defaultLocale) {
        return this.useDefaultLocale();
      }

      await this.localeLoader.loadLocaleData(ngLocale);

      const translations = await this.translationLoader.fetchTranslations(localeId);
      await this.loadTranslations(localeId, translations);

      return await firstValueFrom(this.store.dispatch(new SetLocale(localeId)));
    } catch (error) {
      this.logger.error(`Could not set locale "${locale}"`);
      return this.useDefaultLocale();
    }
  }

  translate(text: string, args: Record<string, any>): string {
    if (!text || !args) return text;
    return text.replace(/\{(\w+)\}/g, (_, key) => (key in args ? String(args[key]) : `{${key}}`));
  }

  translatePlural(value: number | null | undefined, pluralMap: PluralMap, locale?: string): string {
    if (isNil(value)) return '';
    if (isNil(pluralMap)) throw new Error("Argument 'pluralMap' is required.");

    const translationUnit = this.getPluralTranslationUnit(value, pluralMap, locale);

    if (isNil(translationUnit))
      throw new Error(`No TranslationUnit found for plural key: ${value}`);

    return translationUnit.text.replace(INTERPOLATION_REGEXP, value.toString());
  }

  getPluralTranslationUnit(value: number, pluralMap: PluralMap, locale?: string) {
    const key = this.getPluralCategory(value, Object.keys(pluralMap), locale) as keyof PluralMap;
    const translationUnit = pluralMap[key];
    return translationUnit;
  }

  translateSelect(value: string | null | undefined, selectMap: SelectMap) {
    if (isNil(value)) return '';
    if (isNil(selectMap)) throw new Error("Argument 'selectMap' is required.");

    const translationUnit = selectMap[value];

    if (isNil(translationUnit)) throw new Error(`No TranslationUnit found for value: ${value}`);

    return translationUnit.text.replace(INTERPOLATION_REGEXP, value.toString());
  }

  private async useDefaultLocale() {
    this.loadTranslations(defaultLocale);
    return await firstValueFrom(this.store.dispatch(new SetLocale(defaultLocale)));
  }

  private async loadTranslations(
    localeId: LocaleId,
    translations?: Record<MessageId, TargetMessage>,
  ) {
    clearTranslations();
    $localize.locale = localeId;

    if (isDefined(translations)) {
      loadTranslations(translations);
    }

    this.setDocumentI18n(localeId);
  }

  private setDocumentI18n(localeId: LocaleId) {
    this.document.documentElement.dir = getLangDir(localeId);
    this.document.documentElement.lang = localeId;
  }

  private getPluralCategory(value: number, cases: string[], locale?: string): string {
    let key = `=${value}`;

    if (cases.indexOf(key) > -1) {
      return key;
    }

    key = new NgLocaleLocalization(locale || this.currentLocale).getPluralCategory(value, locale);

    if (cases.indexOf(key) > -1) {
      return key;
    }

    if (cases.indexOf('other') > -1) {
      return 'other';
    }

    throw new Error(`No plural message found for value "${value}"`);
  }
}

export const TRANSLATE = new InjectionToken('TRANSLATE', {
  factory: () => {
    const service = inject(TranslationService);
    return service.translate.bind(service);
  },
});

export const TRANSLATE_PLURAL = new InjectionToken('TRANSLATE_PLURAL', {
  factory: () => {
    const service = inject(TranslationService);
    return service.translatePlural.bind(service);
  },
});

export const TRANSLATE_SELECT = new InjectionToken('TRANSLATE_SELECT', {
  factory: () => {
    const service = inject(TranslationService);
    return service.translateSelect.bind(service);
  },
});
