import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetPendingSave } from './classification.actions';

export interface ClassificationStateModel {
  pendingSave: boolean;
}

const defaultState: ClassificationStateModel = {
  pendingSave: false,
};

@State<ClassificationStateModel>({
  name: 'classificationStateModel',
  defaults: defaultState,
})
@Injectable()
export class ClassificationState {
  @Selector() static pendingSave(state: ClassificationStateModel): boolean {
    return state.pendingSave;
  }

  @Action(SetPendingSave) setPendingSave(
    ctx: StateContext<ClassificationStateModel>,
    { pendingSave }: SetPendingSave,
  ) {
    ctx.patchState({ pendingSave });
  }
}
