import { ImportFile, ImportScan } from './import.models';

export class SetCombineToSingleScan {
  static readonly type = '[ImportState] SetCombineToSingleScan';
  constructor(public combineToSingleScan: boolean) {}
}

export class AddToImportScans {
  static readonly type = '[ImportState] AddToImportScans';
  constructor(public importScans: ImportScan[]) {}
}

export class PatchImportScan {
  static readonly type = '[ImportState] PatchImportScan';
  constructor(public importScan: ImportScan) {}
}

export class PatchImportFile {
  static readonly type = '[ImportState] PatchImportFile';
  constructor(public importFile: ImportFile) {}
}

export class ResetImportScans {
  static readonly type = '[ImportState] ResetImportScans';
}
