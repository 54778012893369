import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from '@trimble-gcs/common';
import { ModusTooltipModule } from '@trimble-gcs/modus';
import { interval } from 'rxjs';
import { ScandataModel } from '../../scandata/scandata.models';

interface Progress {
  tooltip: string;
  percentageComplete: number;
}

@UntilDestroy()
@Component({
  selector: 'sd-ingestion-progress',
  standalone: true,
  imports: [CommonModule, ModusTooltipModule, MatProgressSpinnerModule],
  templateUrl: './ingestion-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IngestionProgressComponent {
  // signals
  private updateDisplayInterval = toSignal(interval(1500));

  // inputs
  scan = input.required<ScandataModel>();

  // computes
  progress = computed(() => {
    this.updateDisplayInterval();
    return this.getProgress(this.scan());
  });

  private getProgress(scan: ScandataModel): Progress {
    if (isNil(scan.ingestionStartedDate) || isNil(scan.ingestionEstimatedCompleteDate))
      return { tooltip: 'Queued...', percentageComplete: 0 };

    const nowTime = Date.now();
    const startTime = scan.ingestionStartedDate.getTime();
    const finishTime = scan.ingestionEstimatedCompleteDate.getTime();
    const remainingMinutes = Math.ceil((finishTime - nowTime) / 60000);

    const totalTime = finishTime - startTime;
    const elapsedTime = Math.ceil(nowTime - startTime);
    const elapsedPercentage = Math.floor((elapsedTime / totalTime) * 100);

    const remaining = remainingMinutes <= 1 ? 'less than a minute' : `${remainingMinutes} minutes`;
    const percentage = elapsedPercentage > 99 ? 99 : elapsedPercentage;

    return {
      tooltip: `Ingestion progress: ${percentage}% \nEstimated time remaining: ${remaining}`,
      percentageComplete: percentage,
    };
  }
}
