import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  input,
  output,
  viewChild,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { ImportFile, ImportFileStatus } from '../import.models';

interface ImportFileView {
  id: string;
  name: string;
  size: number;

  statusMessage?: string;
  status?: ImportFileStatus;
  progress?: number;
  progressTooltip: string;
}

@Component({
  selector: 'sd-import-file-list',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusTooltipModule,
    ModusIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './import-file-list.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1 1 auto;
        overflow: hidden;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportFileListComponent {
  progress = viewChild<ElementRef<HTMLDivElement>>('currentUpload');

  importFiles = input.required<ImportFile[]>();
  disableRemove = input.required<boolean>();

  removeFiles = output<ImportFile[]>();

  hasImportFiles = computed(() => this.importFiles().length > 0);
  importFilesView = computed(() => this.mapImportFileView(this.importFiles()));

  importFileStatus = ImportFileStatus;

  constructor() {
    effect(() => {
      const progress = this.progress();
      if (isNil(progress)) return;

      progress.nativeElement.scrollIntoView({ behavior: 'smooth' });
    });
  }

  removeFile(importFileView: ImportFileView) {
    const importFile = this.importFiles().find((file) => file.id === importFileView.id)!;
    this.removeFiles.emit([importFile]);
  }

  private mapImportFileView(importFiles: ImportFile[]) {
    return importFiles.map((importFile) => {
      const progress = importFile.fileUpload?.progress;
      return {
        ...importFile,
        progress,
        progressTooltip: isDefined(progress) ? `Upload progress ${progress}%` : 'Pending',
      } satisfies ImportFileView;
    });
  }
}
