import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isDefined } from '@trimble-gcs/common';
import { ModusIconModule } from '@trimble-gcs/modus';
import { combineLatestWith, filter, map, switchMap, tap } from 'rxjs';
import { ClarityTreeItem, ClarityUserWorld } from '../clarity.models';
import { ClarityService } from '../clarity.service';
import {
  Breadcrumb,
  ClarityFileBrowserBreadcrumbComponent,
} from './clarity-file-browser-breadcrumb.component';
import { ClarityFileBrowserTableComponent } from './clarity-file-browser-table.component';

@UntilDestroy()
@Component({
  selector: 'sd-clarity-file-browser',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ClarityFileBrowserBreadcrumbComponent,
    ClarityFileBrowserTableComponent,
  ],
  templateUrl: './clarity-file-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClarityFileBrowserComponent {
  private readonly clarityService = inject(ClarityService);
  private readonly rootBreadcrumb: Breadcrumb = { name: 'Home' };

  world = input<ClarityUserWorld>();
  selectedItems = input<ClarityTreeItem[]>([]);
  disabled = input<boolean>(false);

  onLoading = output<boolean>();
  onSelectionChange = output<ClarityTreeItem[]>();

  breadcrumbs = signal<Breadcrumb[]>([this.rootBreadcrumb]);
  treeItems = toSignal(this.getTreeItems(), { initialValue: [] });
  showPrevious = computed(() => this.breadcrumbs().length > 1);

  breadcrumbClick(breadcrumb: Breadcrumb) {
    const breadcrumbs = this.breadcrumbs();
    const breadcrumbIndex = breadcrumbs.findIndex((i) => i.id === breadcrumb.id);
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);

    this.breadcrumbs.set(newBreadcrumbs);
  }

  previousClick() {
    const breadcrumbs = this.breadcrumbs().slice(0, -1);
    this.breadcrumbs.set(breadcrumbs);
  }

  folderClick(folder: ClarityTreeItem) {
    const breadcrumbs = [...this.breadcrumbs(), { id: folder.id, name: folder.name }];
    this.breadcrumbs.set(breadcrumbs);
  }

  private getTreeItems() {
    const breadcrumbs$ = toObservable(this.breadcrumbs);
    const selectedItems$ = toObservable(this.selectedItems);

    return toObservable(this.world).pipe(
      filter(isDefined),
      tap(() => this.onLoading.emit(true)),
      switchMap((world) => this.clarityService.getTreeItems(world)),
      switchMap((treeItems) => {
        this.breadcrumbs.set([this.rootBreadcrumb]); // reset breadcrumbs because world changed
        return breadcrumbs$.pipe(map((breadcrumbs) => ({ treeItems, breadcrumbs })));
      }),
      combineLatestWith(selectedItems$),
      map(([{ treeItems, breadcrumbs }, selectedItems]) => {
        const parentId = breadcrumbs.at(-1)?.id;
        return treeItems
          .filter((treeItem) => treeItem.parentId === parentId)
          .map((treeItem) => {
            const selected = isDefined(selectedItems.find((item) => item.id === treeItem.id));
            return { ...treeItem, selected };
          });
      }),
      tap(() => this.onLoading.emit(false)),
    );
  }
}
