import { Scan3dStyle } from '../scan-3d-panel/models/scan-3d-style';
import { Station, StationLoadStatus } from '../station/station.models';
import { Tileset } from '../tileset/tileset.models';
import { Location } from './location.model';
import { ScandataFile } from './scandata-file.model';

export interface ScandataModel {
  id: string;
  name: string;
  captureDate?: Date;
  pointCount: number;
  isClassified: boolean;
  numberOfStations: number;
  scannerType?: string;
  classStatistics: { [key: number]: number };
  notes?: string;
  location?: Location;
  uploadedBy?: string;
  uploadedDate?: Date;
  ingestionStartedDate?: Date;
  ingestionFinishedDate?: Date;
  fileSize: number;
  tags?: string[];
  thumbnailUrl?: string;
  previewUrl?: string;

  //value in square meters (M2)
  area?: number;

  /** Status returned by API */
  status: PointcloudAPIStatus;

  /** Simplified list of Status, mapped from Status returned by API (used for display and in filters panel) */
  pointcloudStatus: PointcloudStatus;

  // Lazy Loaded properties
  files?: ScandataFile[];
  stations?: Station[];
  tilesets?: Tileset[];

  // Transient properties (view)

  /** The model was checked / selected in the 1D list.
   *  Only selected models will display in the Connect 3D extension tree
   *  Indicates selection in 2D map view
   *  Single selection will display model detail the property panel for both the 1D list in explorer and the 3D trees
   *  Multiple selection will display information for all the selected models */
  selected: boolean;

  /** The order in which the scan was selected.
   *  Used by the scandata table when using range select (shift click), it needs to know the last selected scan id.
   *  Used by the multi select options panel to list the scans in order of selection */
  selectedIndex?: number;

  /** Model has children and the view is expanded to load and display these children in the 3D tree */
  expanded: boolean;

  /** User chooses to show model in the scene */
  showInScene: boolean;

  /** Model has different load states for tilesets */
  loadStatus: ScandataLoadStatus;

  /** Model has different display states for tilesets */
  displayStatus: ScandataDisplayStatus;

  /** Stations are loaded on expand from api */
  stationLoadStatus: StationLoadStatus;

  /** Station icons are displayed */
  stationIconsDisplayed: boolean;

  /** Transient properties related to 2D Map */
  hiddenOnMap: boolean;

  /** Web3d model id */
  web3dId: string;

  /** Global override settings used when displaying pointcloud in 3d view */
  scan3dStyle?: Scan3dStyle;

  /**
   * Linked Connect file id if ingestion took place from Connect.
   * Built up by concatenating parts from a ConnectFile,
   * `${file.id}:${file.versionId}:${file.revision}`
   *
   */
  externalFileId?: string;

  /** Estimated time when ingestion should be complete */
  ingestionEstimatedCompleteDate?: Date;
}

export enum ScandataLoadStatus {
  NotLoaded = 'NotLoaded',
  Loading = 'Loading',
  LoadingError = 'LoadingError',
  Loaded = 'Loaded',
}

export enum ScandataDisplayStatus {
  Hidden = 'Hidden',
  AwaitingDisplay = 'AwaitingDisplay',
  Displayed = 'Displayed',
  DisplayError = 'DisplayError',
  AwaitingHide = 'AwaitingHide',
}

export interface UpdateScandataModel {
  name?: string;
  captureDatetimeUtc?: Date | string;
  scannerType?: string;
  notes?: string;
  tags?: string[];
}

export enum PointcloudAPIStatus {
  Initializing = 'Initializing',
  InProgress = 'InProgress',
  Ready = 'Ready',
  Failed = 'Failed',
}

export enum PointcloudStatus {
  Uploading = 'Uploading',
  Processing = 'Processing',
  Ready = 'Ready',
  Failed = 'Failed',
}

export const PointcloudStatusMap = new Map<PointcloudAPIStatus, PointcloudStatus>([
  [PointcloudAPIStatus.Initializing, PointcloudStatus.Uploading],
  [PointcloudAPIStatus.InProgress, PointcloudStatus.Processing],
  [PointcloudAPIStatus.Ready, PointcloudStatus.Ready],
  [PointcloudAPIStatus.Failed, PointcloudStatus.Failed],
]);
