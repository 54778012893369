<div class="flex flex-auto flex-col w-full">
  @if (hasImportFiles()) {
    <div class="flex flex-auto flex-col overflow-hidden text-sm text-gray-8">
      <div class="font-semibold py-1 px-3 bg-gray-light border border-gray-2">File Name</div>
      <div class="overflow-auto border border-t-0 border-gray-2">
        @for (importFile of importFilesView(); track importFile.id) {
          <div
            class="flex flex-row border-b border-gray-2"
            [ngClass]="{
              'text-red':
                importFile.status === importFileStatus.Error ||
                importFile.status === importFileStatus.Skipped,
            }"
          >
            <div class="p-3 overflow-hidden text-ellipsis whitespace-nowrap">
              {{ importFile.name }}
            </div>
            <div class="flex flex-grow pr-3 items-center justify-end">
              <!-- status message -->
              @if (importFile.statusMessage; as statusMessage) {
                <div class="ml-4 mr-4 text-small italic whitespace-nowrap">
                  {{ statusMessage }}
                </div>
              }

              <!-- status icon -->
              @switch (importFile.status) {
                @case (importFileStatus.Pending) {
                  <md-icon modus-tooltip="Pending">hourglass</md-icon>
                }
                @case (importFileStatus.Busy) {
                  @if (importFile.progress; as progress) {
                    <div
                      #currentUpload
                      class="flex h-full w-6 items-center justify-center relative"
                      [modus-tooltip]="importFile.progressTooltip"
                    >
                      <div class="w-5 h-5 absolute border-2 border-gray-1 rounded-full"></div>
                      <mat-progress-spinner
                        class="!w-5 !h-5 relative"
                        mode="determinate"
                        [value]="importFile.progress"
                      >
                      </mat-progress-spinner>
                    </div>
                  } @else {
                    <div
                      #currentUpload
                      class="flex h-full w-6 items-center justify-center relative"
                    >
                      <mat-progress-spinner class="!w-5 !h-5 relative" mode="indeterminate">
                      </mat-progress-spinner>
                    </div>
                  }
                }
                @case (importFileStatus.Completed) {
                  <md-icon modus-tooltip="Upload Completed" class="text-green">check</md-icon>
                }
                @case (importFileStatus.Error) {
                  <md-icon modus-tooltip="Something went wrong">alert_outlined</md-icon>
                }
                @case (importFileStatus.Skipped) {
                  <md-icon modus-tooltip="File Skipped">exclamation_mark</md-icon>
                }
              }

              <!-- remove button -->
              @if (!disableRemove()) {
                <button
                  id="import-list-remove"
                  class="ml-2 text-currentcolor"
                  modus-icon-button
                  modus-tooltip="Remove"
                  (click)="removeFile(importFile)"
                >
                  <md-icon>close</md-icon>
                </button>
              }
            </div>
          </div>
        }
      </div>
    </div>
  }
</div>
