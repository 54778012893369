import { Injectable } from '@angular/core';
import { LocaleId } from './locale-support';
import { TranslationService } from './translation.service';

@Injectable({
  providedIn: 'root',
})
export class LocaleProvider extends String {
  constructor(private translationService: TranslationService) {
    super();
  }

  override toString(): LocaleId {
    return this.translationService.currentLocale;
  }

  override valueOf(): LocaleId {
    return this.toString();
  }
}
