import { inject, Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { FeatureFlagService } from '@trimble-gcs/feature-flags';
import { combineLatest, finalize, map, Observable } from 'rxjs';
import { DialogService } from '../../dialog/dialog.service';
import { ImportFile } from '../import.models';
import { ClarityFilePickerComponent } from './clarity/clarity-file-picker.component';
import { ConnectFilePickerComponent } from './connect/connect-file-picker.component';
import { FILE_PICKERS_CONFIG, FilePicker, PickerType } from './file-picker.models';

@Injectable({
  providedIn: 'root',
})
export class FilePickerService {
  private readonly featureFlagService = inject(FeatureFlagService);
  private readonly dialogService = inject(DialogService);

  private filePickerDialogConfig: MatDialogConfig = {
    disableClose: true,
    height: '70%',
    width: '70%',
    minWidth: '500px',
    maxWidth: '650px',
  };

  getFilePickers(): Observable<FilePicker[]> {
    const features$ = FILE_PICKERS_CONFIG.map((pickerConfig) => {
      return this.featureFlagService
        .hasFeature$(pickerConfig.featureFlagKey)
        .pipe(map((hasFeature) => ({ ...pickerConfig, enabled: hasFeature }) satisfies FilePicker));
    });

    return combineLatest(features$);
  }

  openFilePicker(pickerType: PickerType): Observable<ImportFile[]> {
    switch (pickerType) {
      case PickerType.Clarity:
        return this.openClarityFilePicker();

      case PickerType.Connect:
        return this.openConnectFilePicker();

      default:
        throw new Error(`Import source picker not implemented: ${pickerType}`);
    }
  }

  private openClarityFilePicker() {
    const dialogRef = this.dialogService.showComponent<
      ClarityFilePickerComponent,
      any,
      ImportFile[]
    >(ClarityFilePickerComponent, this.filePickerDialogConfig);

    return dialogRef.afterClosed().pipe(
      map((files) => files ?? []),
      finalize(() => dialogRef.close()),
    );
  }

  private openConnectFilePicker() {
    const dialogRef = this.dialogService.showComponent<
      ConnectFilePickerComponent,
      any,
      ImportFile[]
    >(ConnectFilePickerComponent, this.filePickerDialogConfig);

    return dialogRef.afterClosed().pipe(
      map((files) => files ?? []),
      finalize(() => dialogRef.close()),
    );
  }
}
