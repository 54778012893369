import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, combineLatest, filter, map, switchMap } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { GET_CONNECT_REGION_URL } from '../connect/get-connect-region-url';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';

export const MAX_TAG_LENGTH = 40;

export interface Tag {
  id: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);
  private readonly store = inject(Store);
  private readonly http = inject(HttpClient);

  getTags(): Observable<string[]> {
    return combineLatest([this.getScandataTags(), this.getConnectTags()]).pipe(
      map(([scandataTags, connectTags]) => {
        const uniqueTags = [...new Set([...scandataTags, ...connectTags])];
        return uniqueTags.sort((a, b) => a.localeCompare(b));
      }),
    );
  }

  private getScandataTags(): Observable<string[]> {
    return this.getScanProjectUrl$(`/tags`).pipe(
      switchMap((url) => this.http.get<string[] | undefined>(url)),
      map((tags) => tags ?? []),
    );
  }

  private getConnectTags(): Observable<string[]> {
    const project$ = this.store.selectOnce(AppState.project).pipe(filter(isDefined));

    return project$.pipe(
      switchMap((project) => this.getConnectRegionUrl$(`tags?projectId=${project.id}`)),
      switchMap((url) => {
        return this.http.get<Tag[]>(url);
      }),
      map((tags) => {
        return tags.map((tag) => tag.label);
      }),
    );
  }
}
