<button id="3d-sort" class="text-gray-8" modus-icon-button [modusMenuTriggerFor]="menu">
  <md-icon>sort</md-icon>
</button>

<md-menu #menu>
  @for (item of menuItems(); track item.sortableProperty.property) {
    <md-menu-item
      [ngClass]="{ 'font-semibold !bg-blue-pale text-trimble-blue-dark': item.active }"
      (itemClick)="sortClick(item)"
      class="!w-36"
    >
      <span class="whitespace-nowrap">{{ item.sortableProperty.displayName }}</span>
      <span class="flex flex-grow justify-end">
        @switch (item.direction) {
          @case ('asc') {
            <md-icon class="!text-sm">sort_arrow_up</md-icon>
          }
          @case ('desc') {
            <md-icon class="!text-sm">sort_arrow_down</md-icon>
          }
        }
      </span>
    </md-menu-item>
  }
</md-menu>
