import { Component } from '@angular/core';
import { noProjectAccessTranslations } from './no-project-access.translations';

@Component({
  standalone: true,
  imports: [],
  template: `<div class="m-4">{{ translations.noProjectAccess.text }}</div>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    `,
  ],
})
export class NoProjectAccessComponent {
  readonly translations = noProjectAccessTranslations();
}
