import { Injectable, InjectionToken, inject } from '@angular/core';
import { MessageId, TargetMessage } from '@angular/localize';
import { LocaleId } from './locale-support';

export const TRANSLATION_FILES = new InjectionToken<string>('TRANSLATION_FILES', {
  providedIn: 'root',
  factory: () => '/assets/translations',
});

@Injectable({
  providedIn: 'root',
})
export class TranslationLoader {
  private translationFiles = inject(TRANSLATION_FILES);

  async fetchTranslations(
    localeId: LocaleId,
  ): Promise<Record<MessageId, TargetMessage> | undefined> {
    const response = await fetch(`${this.translationFiles}/${localeId}.json`);
    const json = await response.json();
    return json.translations;
  }
}
