import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { filter, map, of, shareReplay, switchMap, take } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { ProjectSelectState } from '../../hosts/app-bar/project-select/project-select.state';
import { LoadingService } from '../../loading/loading.service';
import { ConnectRegionService } from '../connect-region.service';
import { ConnectRegion } from '../connect.models';

export const projectSelectDialogDefaultConfig: MatDialogConfig = {
  disableClose: true,
  height: '70%',
  minHeight: '300px',
  width: '400px',
};

export type ProjectSelectDialogResult = {
  region: ConnectRegion;
  project: ConnectProject;
};

interface RegionOption extends ConnectRegion {
  displayName: string;
}

@UntilDestroy()
@Component({
  selector: 'sd-project-select-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusTooltipModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSelectModule,
    MatProgressBarModule,
    MatDialogModule,
  ],
  templateUrl: './project-select-dialog.component.html',
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectSelectDialogComponent {
  regionFormControl = new FormControl<ConnectRegion | null>(null);

  isLoading = toSignal(this.loadingService.isLoading$(this));
  regions = toSignal(this.getRegions());

  private projects = toSignal(this.getProjects());
  projectOptions = computed(() => (this.isLoading() ? [] : this.projects()));

  constructor(
    private dialogRef: MatDialogRef<ProjectSelectDialogComponent, ProjectSelectDialogResult>,
    private store: Store,
    private connectRegionService: ConnectRegionService,
    private loadingService: LoadingService,
  ) {
    this.createLoadingEffect();
    this.setRegionInitialValue();
  }

  cancelClick(): void {
    this.dialogRef.close();
  }

  projectClick(project: ConnectProject) {
    const result: ProjectSelectDialogResult = {
      region: this.regionFormControl.value!,
      project,
    };
    this.dialogRef.close(result);
  }

  private createLoadingEffect() {
    effect(() => {
      if (this.isLoading()) this.regionFormControl.disable({ emitEvent: false });
      else this.regionFormControl.enable({ emitEvent: false });
    });
  }

  private setRegionInitialValue() {
    toObservable(this.regions)
      .pipe(
        filter((regions) => isDefined(regions)),
        map((regions) => {
          const cachedRegion = this.store.selectSnapshot(ProjectSelectState.cachedConnectRegion);
          const region = regions.find((region) => region.location === cachedRegion?.location);
          if (isDefined(region)) return region;

          return regions.at(0);
        }),
        take(1),
      )
      .subscribe((region) => this.regionFormControl.setValue(region ?? null));
  }

  private getRegions() {
    return this.connectRegionService.getConnectRegions().pipe(
      map((regions) => regions.map((region) => this.mapToRegionOption(region))),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private mapToRegionOption(region: ConnectRegion) {
    return {
      ...region,
      displayName: region.location
        .split(/(?=[A-Z])/)
        .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`)
        .join(' '),
    } satisfies RegionOption;
  }

  private getProjects() {
    return this.regionFormControl.valueChanges.pipe(
      switchMap((region) => {
        if (isNil(region)) return of([]);

        return this.loadingService.loadFrom(
          this.connectRegionService.getProjectsForRegion(region),
          this,
        );
      }),
      untilDestroyed(this),
    );
  }
}
