import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

export interface SvgIcon {
  name: string;
  resourceUrl: string;
}

@Injectable({
  providedIn: 'root',
})
export class IconRegistryService {
  private svgIcons: SvgIcon[] = [
    { name: 'logo-dark-gray', resourceUrl: '/assets/logo_darkgray_24dp.svg' },
    { name: 'clarity', resourceUrl: '/assets/clarity.svg' },
    { name: 'connect', resourceUrl: '/assets/connect.svg' },
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {}

  registerIcons() {
    this.svgIcons.forEach((svgIcon) => {
      this.matIconRegistry.addSvgIcon(
        svgIcon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(svgIcon.resourceUrl),
      );
    });
  }
}
