<div class="flex flex-col h-full">
  <!-- header -->
  <div mat-dialog-title>
    <div class="flex flex-1 flex-wrap items-center font-bold">Select Connect Project</div>
    <button modus-icon-button modus-tooltip="Close" (click)="cancelClick()">
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <mat-dialog-content class="!p-0 overflow-hidden">
    @if (isLoading()) {
      <div class="w-full relative">
        <div class="w-full absolute">
          <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
        </div>
      </div>
    }
    <div class="flex items-center flex-row p-4 bg-gray-light border-b border-gray-1">
      Region

      <select
        modus-select
        id="connect-region-select"
        class="ml-4 !w-[140px]"
        [formControl]="regionFormControl"
      >
        @for (region of regions(); track region.location) {
          <option [ngValue]="region">{{ region.displayName }}</option>
        }
      </select>
    </div>

    <div class="flex flex-col flex-1 pb-4 overflow-y-auto">
      @for (project of projectOptions(); track project.id) {
        <div
          class="p-4 font-medium cursor-pointer hover:bg-blue-pale"
          (click)="projectClick(project)"
        >
          {{ project.name }}
        </div>
      }
    </div>
  </mat-dialog-content>

  <!-- actions -->
  <mat-dialog-actions>
    <button modus-button color="secondary" (click)="cancelClick()">Cancel</button>
  </mat-dialog-actions>
</div>
