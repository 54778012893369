/**
 * Locales supported by Connect, mapped to LOCALE_ID files defined by Angular in
 * node_modules\@angular\common\locales\global\
 *
 * DEVELOPER NOTE: Keys and values are case-sensitive.
 */
export const supportedLocales = {
  'en-US': 'en',
  'af-ZA': 'af', // Afrikaans          | Afrikaans - South Africa
  'cs-CZ': 'cs', // Čeština            | Czech - Czech Republic
  'da-DK': 'da', // Dansk              | Danish - Denmark
  'de-DE': 'de', // Deutsch            | German - Germany
  'es-ES': 'es', // Español            | Spanish - Spain
  'fi-FI': 'fi', // Suomi              | Finnish - Finland
  'fr-FR': 'fr', // Français           | French - France
  'it-IT': 'it', // Italiano           | Italian - Italy
  'ja-JP': 'ja', // 日本語              | Japanese - Japan
  'ko-KR': 'ko', // 한국어              | Korean - South Korea
  'nb-NO': 'nb', // Norsk (bokmål)     | Norwegian Bokmål - Norway
  'nl-NL': 'nl', // Nederlands         | Dutch - Netherlands
  'pt-BR': 'pt', // Português - Brasil | Portuguese - Brazil
  'ru-RU': 'ru', // Русский            | Russian - Russia
  'sv-SE': 'sv', // Svenska            | Swedish - Sweden
  'zh-TW': 'zh', // 中文（繁體)          | Chinese - Taiwan
  'zh-CN': 'zh', // 中文（简体)          | Simplified Chinese - China
  'hu-HU': 'hu', // Magyar             | Hungarian - Hungary
  'pl-PL': 'pl', // Polski             | Polish - Poland
} as const;

export type LocaleId = keyof typeof supportedLocales;
export type NgLocale = (typeof supportedLocales)[LocaleId];
export type LocaleInfo = { localeId: LocaleId; ngLocale: NgLocale };

export const defaultLocale: LocaleId = 'en-US';

export function isSupported(locale: string): boolean {
  const lowerCaseLocale = locale.toLowerCase();

  const index = Object.keys(supportedLocales).findIndex(
    (key) => key.toLowerCase() === lowerCaseLocale,
  );

  return index >= 0;
}

export function getLocaleInfo(locale: string): LocaleInfo {
  const lowerCaseLocale = locale.toLowerCase();

  const localeKey = Object.keys(supportedLocales).find(
    (key) => key.toLowerCase() === lowerCaseLocale,
  );

  const localeId = (localeKey as LocaleId) ?? defaultLocale;
  const ngLocale = supportedLocales[localeId];

  return { localeId, ngLocale };
}
