import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusSelectModule,
} from '@trimble-gcs/modus';
import { filter } from 'rxjs';
import { ModusAutocompleteModule } from '../../../../../../libs/modus/src/lib/modus-autocomplete/modus-autocomplete.module';
import { LocaleId } from '../locale-support';
import {
  TRANSLATE,
  TRANSLATE_PLURAL,
  TRANSLATE_SELECT,
  TranslationService,
} from '../translation.service';
import { demoTranslations } from './translation-demo.translations';

type User = { name: string; gender: string };

@Component({
  selector: 'sd-translation-demo',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusInputModule,
    ModusSelectModule,
    MatRadioModule,
    MatDividerModule,
    ModusAutocompleteModule,
  ],
  templateUrl: './translation-demo.component.html',
  styleUrl: './translation-demo.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslationDemoComponent {
  private translationService = inject(TranslationService);
  private translate = inject(TRANSLATE);
  private translatePlural = inject(TRANSLATE_PLURAL);
  private translateSelect = inject(TRANSLATE_SELECT);

  translations = signal(demoTranslations());

  // locales
  locales: LocaleId[] = ['en-US', 'af-ZA', 'nl-NL'];
  localeControl = new FormControl<LocaleId>(this.translationService.currentLocale);

  // user & greeting
  users: User[] = [
    { name: 'Ruan', gender: 'male' },
    { name: 'Estaleen', gender: 'female' },
    { name: 'Herman', gender: 'other' },
  ];

  userControl = new FormControl<User | null>(null);

  private userSignal = toSignal(this.userControl.valueChanges, {
    initialValue: this.userControl.value,
  });

  greeting = computed(() => {
    const user = this.userSignal();
    const text = this.translations().greeting.text;
    return user && user.name ? this.translate(text, { name: user.name }) : '';
  });

  // plural quantities
  quantities = [0, 1, 2, 3, 4, 5];
  quantityControl = new FormControl<number>(0, { nonNullable: true });

  private quantitySignal = toSignal(this.quantityControl.valueChanges, {
    initialValue: this.quantityControl.value,
  });

  quantityMessage = computed(() => {
    const quantity = this.quantitySignal();
    const pluralMap = this.translations().plural;
    return this.translatePlural(quantity, pluralMap);
  });

  // select
  models = ['station', 'pointcloud', 'other'];
  modelControl = new FormControl<string>('station', { nonNullable: true });

  private modelSignal = toSignal(this.modelControl.valueChanges, {
    initialValue: this.modelControl.value,
  });

  modelMessage = computed(() => {
    const gender = this.modelSignal();
    const selectMap = this.translations().modelType;
    return this.translateSelect(gender, selectMap);
  });

  // complexMessage
  complexMessage = computed(() => {
    const user: User | null = this.userSignal();
    const model: string = this.modelSignal();
    const quantity: number = this.quantitySignal();

    if (isNil(user)) return '';

    const locale = this.translationService.currentLocale;

    const userText = this.translations().complex.user.text;
    const userTranslation = this.translate(userText, { name: user.name, locale });

    const pronounMap = this.translations().complex.pronoun;
    const pronoun = this.translateSelect(user.gender, pronounMap);

    const conjugationMap = this.translations().complex.conjugation;
    const conjugation = this.translateSelect(user.gender, conjugationMap);

    const modelTypeMap = this.translations().complex.modelType;
    const modelMap = modelTypeMap[model as keyof typeof modelTypeMap];
    const modelText = this.translatePlural(quantity, modelMap);

    const selectionMap = this.translations().complex.selection;
    const selectionUnit = this.translationService.getPluralTranslationUnit(quantity, selectionMap);

    if (isNil(selectionUnit)) return `${userTranslation}`;

    const selection = this.translate(selectionUnit.text, {
      count: quantity,
      modelType: modelText,
    });

    return `${userTranslation}\n${pronoun} ${conjugation} ${selection}`;
  });

  constructor() {
    this.subscribeToLocaleChanges();
  }

  private subscribeToLocaleChanges() {
    this.localeControl.valueChanges.pipe(filter(isDefined)).subscribe(async (value) => {
      await this.translationService.setLocale(value);
      this.translations.update(() => demoTranslations());
    });
  }
}
