import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  EMPTY,
  Observable,
  catchError,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  merge,
  mergeMap,
  of,
  pairwise,
  shareReplay,
  switchMap,
  throttleTime,
  withLatestFrom,
} from 'rxjs';
import { ConnectFile, File3DStatus } from 'trimble-connect-workspace-api';
import { IngestionSource } from '../client-header/client-header.models';
import { Host3dService } from '../connect-3d-ext/host-3d.service';
import { LicenseService } from '../license/license.service';
import { injectLogger } from '../logging/logger-injection';
import { ProjectQuotaService } from '../quota/project-quota.service';
import {
  PointcloudAPIStatus,
  ScandataDisplayStatus,
  ScandataModel,
} from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { ScandataState } from '../scandata/scandata.state';
import { ClearCurrentStation } from '../station/station.actions';
import { StationState } from '../station/station.state';
import { Role } from '../user/user.models';
import { UserState } from '../user/user.state';
import { ConnectIngestionService } from './connect-ingestion.service';
import { ConnectService } from './connect.service';
import { ConnectFile3dStatus } from './models/connect-file-3d-status';
import { ConnectFileSelectEvent } from './models/connect-file-select-event';
import { ExternalFileId } from './models/external-file-id';

@Injectable({
  providedIn: 'root',
})
export class Connect3dPanelService {
  private logger = injectLogger('Connect3dPanelService');

  constructor(
    private connectService: ConnectService,
    private ingestionService: ConnectIngestionService,
    private projectQuotaService: ProjectQuotaService,
    private licenseService: LicenseService,
    private scandataService: ScandataService,
    private host3dService: Host3dService,
    private store: Store,
  ) {
    this.subscribeToDisplayedScans();
    this.subscribeToHiddenScans();
  }

  public subscribeToConnectEvents() {
    const scanClick = this.getScanForFileClick();
    this.setupIconForReadyToRefresh(scanClick);
    this.setupIconForTilingError(scanClick);
    this.subscribeToIconClickAndTile(scanClick);
    this.subscribeToIconClickAndShow(scanClick);
    this.subscribeToIconClickAndHide(scanClick);
  }

  private setupIconForReadyToRefresh(
    scanClick: Observable<{ file: ConnectFile; scan: ScandataModel | undefined }>,
  ) {
    scanClick
      .pipe(
        filter(
          ({ scan }) =>
            scan?.status === PointcloudAPIStatus.Initializing ||
            scan?.status === PointcloudAPIStatus.InProgress,
        ),
        mergeMap(({ file }) => this.setup3dIcon(file.id, ConnectFile3dStatus.ReadyToRefresh)),
      )
      .subscribe();
  }

  private setupIconForTilingError(
    scanClick: Observable<{ file: ConnectFile; scan: ScandataModel | undefined }>,
  ) {
    scanClick
      .pipe(
        filter(
          ({ file, scan }) =>
            (isNil(file.status) || file.status === 'assimilationBusy') &&
            scan?.status === PointcloudAPIStatus.Failed,
        ),
        mergeMap(({ file }) => this.setup3dIcon(file.id, ConnectFile3dStatus.TilingError)),
      )
      .subscribe();
  }

  private subscribeToIconClickAndTile(
    scanClick: Observable<{ file: ConnectFile; scan: ScandataModel | undefined }>,
  ) {
    scanClick
      .pipe(
        filter(({ file, scan }) => this.canTile(file, scan)),
        map(({ file, scan }) => this.forceRetileIfFileWasUpdated(file, scan)),
        mergeMap(({ file }) => this.checkAdmin(file)),
        mergeMap((file) => this.checkQuota(file, true)),
        mergeMap((file) =>
          this.setup3dIcon(file.id, ConnectFile3dStatus.Tiling).pipe(
            switchMap(() => this.getFileDownloadUrl(file)),
            switchMap((downloadUrl) => this.createIngestion(file, downloadUrl)),
            switchMap((file) => this.setup3dIcon(file.id, ConnectFile3dStatus.ReadyToRefresh)),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeToIconClickAndShow(
    scanClick: Observable<{ file: ConnectFile; scan: ScandataModel | undefined }>,
  ) {
    scanClick
      .pipe(
        filter(({ file, scan }) => this.canShow(file, scan)),
        mergeMap(({ file, scan }) =>
          this.setup3dIcon(file.id, ConnectFile3dStatus.Loading).pipe(
            map(() => ({ file, scan: scan as ScandataModel })),
          ),
        ),
        mergeMap(({ file, scan }) => this.checkQuota(file, false).pipe(map(() => scan))),
        mergeMap((scan) => this.host3dService.showScan(scan)),
      )
      .subscribe();
  }

  private subscribeToIconClickAndHide(
    scanClick: Observable<{ file: ConnectFile; scan: ScandataModel | undefined }>,
  ) {
    scanClick
      .pipe(
        filter(({ file }) => file.status === 'loaded'),
        mergeMap(({ file }) =>
          this.setup3dIcon(file.id, ConnectFile3dStatus.Unloading).pipe(map(() => file)),
        ),
        mergeMap((file) => this.hideAllScansAndStations(file.id)),
      )
      .subscribe();
  }

  private getScanForFileClick() {
    return this.observeIconClicked().pipe(
      filter((fileEvent) => this.allowFileClick(fileEvent.file)),
      throttleTime(200),
      switchMap((fileEvent) =>
        this.setup3dIcon(fileEvent.file.id, ConnectFile3dStatus.CheckingStatus).pipe(
          map(() => fileEvent.file),
        ),
      ),
      concatMap((file) => this.checkLicense(file)),
      mergeMap((file) => this.checkConnectFile(file)),
      mergeMap((file) => this.getScan(file)),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private observeIconClicked() {
    return from(this.connectService.getWorkspace()).pipe(
      switchMap((workspace) => workspace.event$),
      filter((workspaceEvent) => workspaceEvent.id === 'extension.fileViewClicked'),
      map((workspaceEvent) => workspaceEvent.data as ConnectFileSelectEvent),
      filter((fileEvent) => fileEvent.source.startsWith('3dviewer')),
    );
  }

  private allowFileClick(file: ConnectFile) {
    return (
      isNil(file.status) ||
      file.status === 'assimilationFailed' ||
      file.status === 'assimilationBusy' ||
      file.status === 'loaded' ||
      file.status === 'loadingFailed' ||
      file.status === 'unloaded'
    );
  }

  private getScan(file: ConnectFile) {
    const externalFileId = new ExternalFileId(file);

    if (isNil(externalFileId.id))
      return this.handleError(file, new Error('ExternalFileId is not defined'));

    const scans = this.store.selectSnapshot(ScandataState.scandata);

    const scan =
      scans.find(
        (scan) =>
          isDefined(externalFileId.fileId) &&
          isDefined(externalFileId.hash) &&
          scan.externalFileId?.includes(externalFileId.fileId) &&
          scan.externalFileId?.includes(externalFileId.hash),
      ) ??
      scans.find((scan) => scan.externalFileId === externalFileId.idWithoutHash) ??
      scans.find((scan) => scan.externalFileId === externalFileId.fileId);

    const scanIsReadyOrFailed =
      scan?.status === PointcloudAPIStatus.Ready || scan?.status === PointcloudAPIStatus.Failed;

    return scanIsReadyOrFailed
      ? of({ file, scan })
      : this.ingestionService.getScanForConnectFile(file).pipe(
          map((scan) => ({ file, scan })),
          catchError((err) => this.handleError(file, err)),
        );
  }

  private getFileDownloadUrl(file: ConnectFile) {
    return this.connectService.getFileDownloadUrl(file).pipe(
      map((download) => download.url),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private getFile3dStatus(status: ConnectFile3dStatus): File3DStatus {
    switch (status) {
      case ConnectFile3dStatus.CheckingStatus:
        return 'loadingWithoutCancel';
      case ConnectFile3dStatus.ReadyToRefresh:
        return 'assimilationBusy';
      case ConnectFile3dStatus.Tiling:
        return 'assimilating';
      case ConnectFile3dStatus.IngestionError:
      case ConnectFile3dStatus.QuotaExceeded:
      case ConnectFile3dStatus.QuotaWillBeExceeded:
      case ConnectFile3dStatus.TilingError:
      case ConnectFile3dStatus.NotAdmin:
      case ConnectFile3dStatus.NoActiveLicense:
        return 'assimilationFailed';
      case ConnectFile3dStatus.Loading:
        return 'loadingWithoutCancel';
      case ConnectFile3dStatus.LoadingFailed:
        return 'loadingFailed';
      case ConnectFile3dStatus.Loaded:
        return 'loaded';
      case ConnectFile3dStatus.Unloading:
        return 'loadingWithoutCancel';
      case ConnectFile3dStatus.Unloaded:
        return 'unloaded';
    }
  }

  private getFileStatusMessage(status: ConnectFile3dStatus) {
    switch (status) {
      case ConnectFile3dStatus.CheckingStatus:
        return 'Checking status...';
      case ConnectFile3dStatus.IngestionError:
        return 'Error. Click to refresh.';
      case ConnectFile3dStatus.QuotaExceeded:
        return 'Quota exceeded.';
      case ConnectFile3dStatus.QuotaWillBeExceeded:
        return 'Quota will be exceeded.';
      case ConnectFile3dStatus.ReadyToRefresh:
        return 'Busy tiling. Click to refresh.';
      case ConnectFile3dStatus.Tiling:
        return 'Tiling...';
      case ConnectFile3dStatus.TilingError:
        return 'Tiling failed...';
      case ConnectFile3dStatus.Loading:
        return '';
      case ConnectFile3dStatus.LoadingFailed:
        return '';
      case ConnectFile3dStatus.Loaded:
        return '';
      case ConnectFile3dStatus.NotAdmin:
        return 'Only admin users can tile this file.';
      case ConnectFile3dStatus.NoActiveLicense:
        return 'No active license for project.';
      case ConnectFile3dStatus.Unloading:
        return '';
      case ConnectFile3dStatus.Unloaded:
        return '';
    }
  }

  private createIngestion(file: ConnectFile, downloadUrl: string) {
    return this.ingestionService.createIngestion(file, downloadUrl, IngestionSource.Connect3D).pipe(
      map(() => file),
      catchError((err) => this.handleError(file, err)),
    );
  }

  private checkAdmin(file: ConnectFile) {
    return this.store
      .selectOnce(UserState.userRole)
      .pipe(
        switchMap((role) =>
          role === Role.Admin
            ? of(file)
            : this.setup3dIcon(file.id, ConnectFile3dStatus.NotAdmin).pipe(switchMap(() => EMPTY)),
        ),
      );
  }

  private checkConnectFile(file: ConnectFile): Observable<ConnectFile> {
    return isDefined(file.versionId) &&
      isDefined(file.revision) &&
      isDefined(file.hash) &&
      isDefined(file.size)
      ? of(file)
      : this.setup3dIcon(file.id, ConnectFile3dStatus.IngestionError).pipe(switchMap(() => EMPTY));
  }

  private checkLicense(file: ConnectFile) {
    return this.licenseService
      .hasActiveLicense()
      .pipe(
        switchMap((hasActiveLicense) =>
          hasActiveLicense
            ? of(file)
            : this.setup3dIcon(file.id, ConnectFile3dStatus.NoActiveLicense).pipe(
                switchMap(() => EMPTY),
              ),
        ),
      );
  }

  private checkQuota(file: ConnectFile, addFileSize: boolean) {
    return this.projectQuotaService
      .quotaExceeded(addFileSize ? (file.size ?? 0) : 0)
      .pipe(
        switchMap((quotaExceeded) =>
          quotaExceeded
            ? this.setup3dIcon(
                file.id,
                addFileSize
                  ? ConnectFile3dStatus.QuotaWillBeExceeded
                  : ConnectFile3dStatus.QuotaExceeded,
              ).pipe(switchMap(() => EMPTY))
            : of(file),
        ),
      );
  }

  private canTile(file: ConnectFile, scan?: ScandataModel) {
    const newScan = isNil(scan);
    const newVersion = isDefined(scan) && this.checkIfFileWasUpdated(file, scan);

    const retryOnTileError =
      isDefined(scan) &&
      scan.status === PointcloudAPIStatus.Failed &&
      file.status === 'assimilationFailed';

    return newScan || newVersion || retryOnTileError;
  }

  private canShow(file: ConnectFile, scan?: ScandataModel) {
    return (
      isDefined(scan) &&
      scan.status === PointcloudAPIStatus.Ready &&
      !this.checkIfFileWasUpdated(file, scan) &&
      file.status !== 'loaded'
    );
  }

  private forceRetileIfFileWasUpdated(file: ConnectFile, scan?: ScandataModel) {
    return isDefined(scan) && this.checkIfFileWasUpdated(file, scan)
      ? { file, scan: undefined }
      : { file, scan };
  }

  private checkIfFileWasUpdated(file: ConnectFile, scan: ScandataModel) {
    const hash = new ExternalFileId(scan.externalFileId ?? '').hash;
    if (isDefined(hash)) return hash !== file.hash;

    const fileModifiedOn = new Date(file.modifiedOn ?? 0);
    const scanUploadedDate = new Date(scan.uploadedDate ?? 0);
    return fileModifiedOn > scanUploadedDate && (file.revision ?? 1) > 1;
  }

  private setup3dIcon(connectFileId: string, status: ConnectFile3dStatus) {
    return from(this.connectService.getWorkspace()).pipe(
      switchMap((workspace) =>
        from(
          workspace.api.ui.addCustomFileAction([
            {
              fileId: connectFileId,
              fileStatusIcon: {
                fileStatus: this.getFile3dStatus(status),
                fileStatusMessage: this.getFileStatusMessage(status),
              },
            },
          ]),
        ),
      ),
    );
  }

  private setIconToUnloaded(scan: ScandataModel, allScans: ScandataModel[]) {
    const fileId = new ExternalFileId(scan.externalFileId).fileId;
    if (isNil(fileId)) return of(false);

    const filteredScans = allScans.filter((x) => x.externalFileId?.includes(fileId));
    const scanIsStillShown = filteredScans.some((x) => x.showInScene);
    return scanIsStillShown ? of(true) : this.setup3dIcon(fileId, ConnectFile3dStatus.Unloaded);
  }

  private setupIconByExternalFileId(scan: ScandataModel, status: ConnectFile3dStatus) {
    const fileId = new ExternalFileId(scan.externalFileId).fileId;
    if (isNil(fileId)) return of(false);

    return this.setup3dIcon(fileId, status);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError(file: ConnectFile, err: any) {
    this.logger.error(`3d Ingesting error (${file.name})`, {}, err);
    return this.setup3dIcon(file.id, ConnectFile3dStatus.IngestionError).pipe(
      switchMap(() => EMPTY),
    );
  }

  private subscribeToDisplayedScans() {
    const firstDisplayStatus$ = this.getFirstStatusChangeForTiledScans(
      ScandataDisplayStatus.AwaitingDisplay,
    );

    const hasExternalFileId$ = firstDisplayStatus$.pipe(
      map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
    );

    const needsExternalFileId$ = firstDisplayStatus$.pipe(
      map((scans) => scans.filter((x) => isNil(x.externalFileId))),
      filter((scans) => scans.length > 0),
      switchMap((scans) => forkJoin(scans.map((x) => this.scandataService.getScandataModel(x.id)))),
      map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
    );

    merge(hasExternalFileId$, needsExternalFileId$)
      .pipe(
        filter((scans) => scans.length > 0),
        concatMap((scans) =>
          forkJoin(
            scans.map((scan) => this.setupIconByExternalFileId(scan, ConnectFile3dStatus.Loaded)),
          ),
        ),
      )
      .subscribe();
  }

  private subscribeToHiddenScans() {
    this.getFirstStatusChangeForTiledScans(ScandataDisplayStatus.Hidden)
      .pipe(
        map((scans) => scans.filter((x) => isDefined(x.externalFileId))),
        filter((scans) => scans.length > 0),
        withLatestFrom(this.store.select(ScandataState.scandata)),
        concatMap(([hiddenScans, allScans]) =>
          forkJoin(hiddenScans.map((scan) => this.setIconToUnloaded(scan, allScans))),
        ),
      )
      .subscribe();
  }

  private getFirstStatusChangeForTiledScans(status: ScandataDisplayStatus) {
    const tiledScans$ = this.store.select(ScandataState.scandata).pipe(
      map((scans) => scans.filter((x) => x.status === PointcloudAPIStatus.Ready)),
      filter((scans) => scans.length > 0),
    );

    const firstStatus$ = tiledScans$.pipe(
      map((scans) => scans.filter((x) => x.displayStatus === status)),
      pairwise(),
      map(([prev, curr]) =>
        isNil(prev) ? curr : curr.filter((c) => isNil(prev.find((p) => p.id === c.id))),
      ),
    );

    return firstStatus$;
  }

  private hideAllScansAndStations(fileId: string) {
    const displayedScans = this.store
      .selectSnapshot(ScandataState.scandata)
      .filter(
        (x) =>
          x.externalFileId?.includes(fileId) &&
          (x.displayStatus === ScandataDisplayStatus.AwaitingDisplay ||
            x.displayStatus === ScandataDisplayStatus.Displayed),
      );

    return this.host3dService.hideScans(displayedScans).pipe(
      switchMap(() => this.store.selectOnce(StationState.currentStation)),
      switchMap((currentStation) =>
        isDefined(currentStation) ? this.store.dispatch(new ClearCurrentStation()) : of(true),
      ),
    );
  }
}
