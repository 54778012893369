import { isDefined } from '@trimble-gcs/common';
import {
  Filters,
  NO_SCANNER_TYPE_FILTER,
  NO_TAGS_FILTER,
  PageInfo,
  ScandataQuery,
  SortInfo,
} from './scandata-query.models';
import { PointcloudStatus, PointcloudStatusMap } from './scandata.models';

export function getScandataQuerystring(query: Partial<ScandataQuery>): string {
  const parts: string[] = [];

  if (isDefined(query.sortInfo)) parts.push(getSortInfoQueryString(query.sortInfo));
  if (isDefined(query.pageInfo)) parts.push(getPageInfoQueryString(query.pageInfo));
  if (isDefined(query.filters)) parts.push(getFiltersQuerystring(query.filters));

  return parts.filter((part) => part.length > 0).join('&');
}

export function getFiltersFromQueryParameter(queryParameter: string): Filters {
  const decoded = decodeURIComponent(queryParameter);
  const filterPairs = decoded
    .trim()
    .split(',')
    .map((x) => removeOuterParenthesis(x))
    .map((x) => x.trim());

  const name = filterPairs.find((x) => x.includes('name='))?.replace('name=', '');

  // upload date
  const uploadFromDateValue = filterPairs
    .find((x) => x.includes('uploadedDate>='))
    ?.replace('uploadedDate>=', '');
  const uploadFromDate = isDefined(uploadFromDateValue) ? new Date(uploadFromDateValue) : undefined;

  const uploadToDateValue = filterPairs
    .find((x) => x.includes('uploadedDate<='))
    ?.replace('uploadedDate<=', '');
  const uploadToDate = isDefined(uploadToDateValue) ? new Date(uploadToDateValue) : undefined;

  // capture date
  const captureFromDateValue = filterPairs
    .find((x) => x.includes('captureDate>='))
    ?.replace('captureDate>=', '');
  const captureFromDate = isDefined(captureFromDateValue)
    ? new Date(captureFromDateValue)
    : undefined;

  const captureToDateValue = filterPairs
    .find((x) => x.includes('captureDate<='))
    ?.replace('captureDate<=', '');
  const captureToDate = isDefined(captureToDateValue) ? new Date(captureToDateValue) : undefined;

  // status - Will be using PointcloudStatus as input from query parameter.  Not PointcloudAPIStatus
  const statusValues = filterPairs.find((x) => x.includes('status='))?.replaceAll('status=', '');
  const status = isDefined(statusValues)
    ? statusValues
        .split('|')
        .map((x) => PointcloudStatus[x as keyof typeof PointcloudStatus])
        .filter((x): x is PointcloudStatus => isDefined(x))
    : undefined;

  // scanner type
  const noScannerType =
    filterPairs.find((x) => x.includes('noScannerType='))?.replace('noScannerType=', '') === 'true';

  const scannerType = noScannerType
    ? NO_SCANNER_TYPE_FILTER
    : filterPairs.find((x) => x.includes('scannerType='))?.replace('scannerType=', '');

  // tags
  const tagsMatchAll = filterPairs.filter((x) => x.includes('tags=')).length > 1;

  const tagsValues = tagsMatchAll
    ? filterPairs.filter((x) => x.includes('tags=')).map((x) => x.replace('tags=', ''))
    : (filterPairs
        .find((x) => x.includes('tags='))
        ?.replaceAll('tags=', '')
        .split('|') ?? []);

  const noTags = filterPairs.find((x) => x.includes('noTags='))?.replace('noTags=', '') === 'true';
  if (noTags) tagsValues.push(NO_TAGS_FILTER);

  const tags = tagsValues.length > 0 ? tagsValues : undefined;

  // boolean filters
  const isClassified =
    filterPairs.find((x) => x.includes('isClassified='))?.split('=')[1] === 'true';

  const containsStations =
    filterPairs.find((x) => x.includes('containsStations='))?.split('=')[1] === 'true';

  return {
    name,
    status,
    uploadFromDate,
    uploadToDate,
    captureFromDate,
    captureToDate,
    scannerType,
    tags,
    tagsMatchAll: noTags ? false : tagsMatchAll,
    uploadedBy: undefined,
    isClassified: isClassified || undefined,
    containsStations: containsStations || undefined,
  };
}

function getSortInfoQueryString(sortInfo: SortInfo) {
  const sortInfoQuery =
    sortInfo.sortBy?.trim().length > 0 ? `sortBy=${sortInfo.sortBy} ${sortInfo.sortDirection}` : '';
  return sortInfoQuery;
}

function getPageInfoQueryString(pageInfo: PageInfo) {
  const pageInfoQuery = `pageSize=${pageInfo.pageSize}&pageIndex=${pageInfo.pageIndex}`;
  return pageInfoQuery;
}

function getFiltersQuerystring(filters: Filters) {
  const filterPairs: string[] = [];

  if (isDefined(filters.name) && filters.name.length > 0)
    filterPairs.push(`name=*${escapeFilterSpecialChars(filters.name)}/i`);

  if (isDefined(filters.uploadFromDate)) {
    const dateValue = new Date(filters.uploadFromDate).toISOString();
    filterPairs.push(`uploadedDate>=${escapeFilterSpecialChars(dateValue)}`);
  }

  if (isDefined(filters.uploadToDate)) {
    const dateValue = new Date(filters.uploadToDate).toISOString();
    filterPairs.push(`uploadedDate<=${escapeFilterSpecialChars(dateValue)}`);
  }
  if (isDefined(filters.captureFromDate)) {
    const dateValue = new Date(filters.captureFromDate).toISOString();
    filterPairs.push(`captureDate>=${escapeFilterSpecialChars(dateValue)}`);
  }

  if (isDefined(filters.captureToDate)) {
    const dateValue = new Date(filters.captureToDate).toISOString();
    filterPairs.push(`captureDate<=${escapeFilterSpecialChars(dateValue)}`);
  }

  if (isDefined(filters.status) && filters.status.length > 0) {
    const statusFilter = [...PointcloudStatusMap]
      .filter(([, status]) => filters.status?.some((item) => item === status))
      .map(([apiStatus]) => `status=${escapeFilterSpecialChars(apiStatus)}`)
      .join('|');
    filterPairs.push(`(${statusFilter})`);
  }

  if (isDefined(filters.scannerType) && filters.scannerType.length > 0) {
    if (filters.scannerType === NO_SCANNER_TYPE_FILTER) {
      filterPairs.push('noScannerType=true');
    } else {
      filterPairs.push(`scannerType=*${escapeFilterSpecialChars(filters.scannerType)}/i`);
    }
  }

  if (isDefined(filters.tags) && filters.tags.length > 0) {
    const tagsFilter = filters.tags.map((tag) => {
      if (tag === NO_TAGS_FILTER) {
        return 'noTags=true';
      } else {
        return `tags=${escapeFilterSpecialChars(tag)}`;
      }
    });

    if (filters.tagsMatchAll) {
      filterPairs.push(...tagsFilter);
    } else {
      filterPairs.push(`(${tagsFilter.join('|')})`);
    }
  }

  if (isDefined(filters.uploadedBy) && filters.uploadedBy.length > 0) {
    const uploadedByFilter = filters.uploadedBy
      .map((item) => `uploadedByTIDUuid=${escapeFilterSpecialChars(item)}`)
      .join('|');
    filterPairs.push(`(${uploadedByFilter})`);
  }

  if (isDefined(filters.isClassified)) filterPairs.push(`isClassified=${filters.isClassified}`);

  if (isDefined(filters.containsStations)) filterPairs.push(`numberOfStations>0`);

  return filterPairs.length > 0 ? `filterBy=${encodeURIComponent(filterPairs.join(','))}` : '';
}

/**
 * Escape special characters used in filter values.
 * More information: https://alirezanet.github.io/Gridify/guide/filtering#escaping
 */
function escapeFilterSpecialChars(value: string) {
  return value.replace(/([(),|\\]|\/i)/g, '\\$1');
}

function removeOuterParenthesis(value: string) {
  return value.slice(
    value.charAt(0) === '(' ? 1 : 0,
    value.charAt(value.length - 1) === ')' ? -1 : undefined,
  );
}
