import { inject, Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { filter, interval, map, switchMap } from 'rxjs';
import { Filters } from '../scandata/scandata-query.models';
import { PatchScandataModels } from '../scandata/scandata.actions';
import { PointcloudStatus, ScandataModel } from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { ScandataState } from '../scandata/scandata.state';
import { ingestionEstimateConfig, mimimumIngestionSeconds } from './ingestion.models';

const REFRESH_QUEUED_SCANS_INTERVAL_MILLISECONDS = 15000;
const REFRESH_COMPLETED_SCANS_INTERVAL_MILLISECONDS = 45000;

@Injectable({
  providedIn: 'root',
})
export class IngestionService {
  private readonly store = inject(Store);
  private readonly scandataService = inject(ScandataService);

  subscribeScandataWatcher() {
    /**
     * In order to estimate the completion time of scans that are currently processing, we need to:
     * 1) Monitor the store for scans in processing state.
     * 2) Periodically fetch scans in processing state that does not have a ingestion start time or pointcount.
     * 3) Reload scans that are estimated to be completed.
     */

    this.getScansToEstimate()
      .pipe(switchMap((scans) => this.setIngestionEstimate(scans)))
      .subscribe();

    this.getQueuedScans()
      .pipe(switchMap((scans) => this.refreshQueuedScans(scans)))
      .subscribe();

    this.getEstimatedCompleteScans()
      .pipe(switchMap((scans) => this.refreshCompletedScans(scans)))
      .subscribe();
  }

  private getScansToEstimate() {
    return this.store.select(ScandataState.scandata).pipe(
      map((scans) =>
        scans.filter((scan) => {
          return (
            scan.pointcloudStatus === PointcloudStatus.Processing &&
            isDefined(scan.ingestionStartedDate) &&
            isDefined(scan.pointCount) &&
            isNil(scan.ingestionEstimatedCompleteDate)
          );
        }),
      ),
      filter((scans) => scans.length > 0),
    );
  }

  private setIngestionEstimate(scans: ScandataModel[]) {
    const patchScans = scans.map((scan) => ({
      id: scan.id,
      ingestionEstimatedCompleteDate: this.getEstimatedCompleteDate(
        scan.ingestionStartedDate!,
        scan.pointCount,
      ),
    }));

    return this.store.dispatch(new PatchScandataModels(patchScans));
  }

  private getQueuedScans() {
    return interval(REFRESH_QUEUED_SCANS_INTERVAL_MILLISECONDS).pipe(
      switchMap(() => this.store.selectOnce(ScandataState.scandata)),
      map((scans) =>
        scans.filter((scan) => {
          return (
            scan.pointcloudStatus === PointcloudStatus.Processing &&
            (isNil(scan.pointCount) || isNil(scan.ingestionStartedDate))
          );
        }),
      ),
      filter((scans) => scans.length > 0),
    );
  }

  private refreshQueuedScans(updateScans: ScandataModel[]) {
    /**
     * Using scan id to fetch the scans could result in a querystring exceeding max length,
     * instead we will fetch all scans in processing state and only update the scans
     * supplied in the method parameter.
     */
    const filters: Filters = { status: [PointcloudStatus.Processing] };

    return this.scandataService.getScansByQuery({ filters }).pipe(
      map((scans) => {
        return scans.filter((scan) =>
          isDefined(
            updateScans.find((updateScan) => {
              return (
                scan.id === updateScan.id &&
                isDefined(scan.pointCount) &&
                isDefined(scan.ingestionStartedDate)
              );
            }),
          ),
        );
      }),
      switchMap((scans) => this.store.dispatch(new PatchScandataModels(scans))),
    );
  }

  private getEstimatedCompleteScans() {
    return interval(REFRESH_COMPLETED_SCANS_INTERVAL_MILLISECONDS).pipe(
      switchMap(() => this.store.selectOnce(ScandataState.scandata)),
      map((scans) => {
        const now = new Date();
        return scans.filter(
          (scan) =>
            scan.pointcloudStatus === PointcloudStatus.Processing &&
            isDefined(scan.ingestionEstimatedCompleteDate) &&
            scan.ingestionEstimatedCompleteDate <= now,
        );
      }),
      filter((scans) => scans.length > 0),
    );
  }

  private refreshCompletedScans(refreshScans: ScandataModel[]) {
    /**
     * Using scan id to fetch the scans could result in a querystring exceeding max length,
     * instead we will fetch all scans in ready or error state and only update the scans
     * supplied in the method parameter.
     */
    const filters: Filters = { status: [PointcloudStatus.Ready, PointcloudStatus.Failed] };

    return this.scandataService.getScansByQuery({ filters }).pipe(
      map((scans) => {
        return scans.filter((scan) =>
          isDefined(
            refreshScans.find((refreshScan) => {
              return (
                scan.id === refreshScan.id && scan.pointcloudStatus !== PointcloudStatus.Processing
              );
            }),
          ),
        );
      }),
      switchMap((scans) => this.store.dispatch(new PatchScandataModels(scans))),
    );
  }

  private getEstimatedCompleteDate(startDate: Date, points: number): Date {
    const estimatedSeconds = this.getEstimatedIngestionDurationSeconds(points);

    const estimatedComplete = new Date(startDate);
    estimatedComplete.setSeconds(estimatedComplete.getSeconds() + estimatedSeconds);

    return estimatedComplete;
  }

  private getEstimatedIngestionDurationSeconds(points: number): number {
    const config = ingestionEstimateConfig.find((config) => {
      return points >= config.pointsMin && points <= (config.pointsMax ?? points);
    });

    const estimate = isDefined(config) ? points * config.factor + config.minimum : 0;

    return estimate < mimimumIngestionSeconds ? mimimumIngestionSeconds : estimate;
  }
}
