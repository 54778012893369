import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusMenu,
  ModusMenuModule,
  ModusMenuTrigger,
} from '@trimble-gcs/modus';
import { filter } from 'rxjs';
import { CloseSortMenu } from '../../scan-3d-panel/scan-3d.actions';
import { SortInfo } from '../../scandata/scandata-query.models';
import { ScandataModel } from '../../scandata/scandata.models';

export interface SortOption {
  sortableProperty: SortableProperty;
  direction?: 'asc' | 'desc';
  active: boolean;
}

export interface SortableProperty {
  property: keyof ScandataModel;
  displayName: string;
}

const sortableProperties: SortableProperty[] = [
  { property: 'name', displayName: 'Name' },
  { property: 'uploadedDate', displayName: 'Upload Date' },
  { property: 'captureDate', displayName: 'Capture Date' },
];

@UntilDestroy()
@Component({
  selector: 'sd-scandata-sort-menu',
  standalone: true,
  imports: [ModusIconModule, ModusButtonModule, ModusMenuModule],
  templateUrl: './scandata-sort-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataSortMenuComponent {
  @ViewChild(ModusMenu) menu!: ModusMenu;
  @ViewChild(ModusMenuTrigger) menuTrigger!: ModusMenuTrigger;

  sortInfo = input.required<SortInfo>();
  onSortClick = output<SortInfo>();

  menuItems = computed(() => this.getMenuItems(this.sortInfo()));

  constructor(private actions$: Actions) {
    this.subscribeToCloseSortMenu();
  }

  sortClick(sortOption: SortOption) {
    this.onSortClick.emit({
      sortBy: sortOption.sortableProperty.property,
      sortDirection: sortOption.direction === 'asc' ? 'desc' : 'asc',
    });
  }

  private subscribeToCloseSortMenu() {
    this.actions$
      .pipe(
        ofActionDispatched(CloseSortMenu),
        filter(() => this.menu.isOpen),
        untilDestroyed(this),
      )
      .subscribe(() => this.menuTrigger.closeMenu());
  }

  private getMenuItems(activeSort: SortInfo): SortOption[] {
    return sortableProperties.map((sortableProperty) => {
      const direction =
        sortableProperty.property === activeSort.sortBy && activeSort.sortDirection !== ''
          ? activeSort.sortDirection
          : undefined;

      return {
        sortableProperty,
        direction,
        active: isDefined(direction),
      } satisfies SortOption;
    });
  }
}
