import { isDefined, isString, isNil } from '@trimble-gcs/common';
import { ConnectFile } from 'trimble-connect-workspace-api';

export class ExternalFileId {
  readonly fileId: string | null = null;
  readonly versionId: string | null = null;
  readonly revision: string | null = null;
  readonly hash: string | null = null;

  get revisionLabel(): string | null {
    return isDefined(this.revision) ? `v.${this.revision}` : null;
  }

  get id(): string | null {
    return this.fileId && this.versionId && this.revision && this.hash
      ? `${this.fileId}:${this.versionId}:${this.revision}:${this.hash}`
      : null;
  }

  get idWithoutHash(): string | null {
    return this.fileId && this.versionId && this.revision
      ? `${this.fileId}:${this.versionId}:${this.revision}`
      : null;
  }

  constructor(idOrFile?: string | ConnectFile) {
    const parsed = isString(idOrFile)
      ? this.fromString(idOrFile)
      : this.fromFile(idOrFile as ConnectFile);

    Object.assign(this, parsed);
  }

  private fromString(id: string) {
    if (id.length === 0) return {};

    const parts = id.split(':');

    return {
      fileId: parts[0],
      versionId: parts.length > 1 ? parts[1] : null,
      revision: parts.length > 2 ? parts[2] : null,
      hash: parts.length > 3 ? parts[3] : null,
    };
  }

  private fromFile(file: ConnectFile) {
    if (isNil(file)) return {};

    return {
      fileId: file.id ?? null,
      versionId: file.versionId ?? null,
      revision: file.revision?.toString() ?? null,
      hash: file.hash ?? null,
    };
  }
}
