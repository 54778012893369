import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

export interface Breadcrumb {
  id: string;
  name: string;
}

@UntilDestroy()
@Component({
  selector: 'sd-connect-file-breadcrumb',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './connect-file-breadcrumb.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectFileBreadcrumbComponent {
  breadcrumbs = input<Breadcrumb[]>([]);
  disabled = input(false);

  onBreadcrumbClick = output<Breadcrumb>();

  breadcrumbClick(breadcrumb: Breadcrumb) {
    if (this.disabled()) return;

    this.onBreadcrumbClick.emit(breadcrumb);
  }
}
