<table mat-table [dataSource]="dataSource()" [trackBy]="getItemId" class="select-none">
  <!-- selected column -->
  <ng-container matColumnDef="selected">
    <th mat-header-cell *matHeaderCellDef class="!p-1 w-11">
      <modus-checkbox
        [formControl]="selectAllControl"
        (checkboxClick)="toggleAllRows()"
        (keydown.space)="$event.preventDefault()"
      >
      </modus-checkbox>
    </th>
    <td mat-cell *matCellDef="let item" class="!p-1 text-gray-6">
      @if (item.type === treeItemType.file) {
        <modus-checkbox
          [checked]="item.selected"
          (keydown.enter)="checkboxKeyDown(item)"
          (keydown.space)="checkboxKeyDown(item); $event.preventDefault()"
        ></modus-checkbox>
      }
    </td>
  </ng-container>

  <!-- thumbnailUrl column -->
  <ng-container matColumnDef="thumbnailUrl">
    <th mat-header-cell *matHeaderCellDef class="!p-1 w-11 min-w-11 max-w-11 text-small"></th>
    <td mat-cell *matCellDef="let item" class="!p-1 text-gray-6">
      <div class="flex justify-center relative">
        @if (item.type === treeItemType.previous) {
          <md-icon class="!text-2xl">arrow_up</md-icon>
        } @else if (item.type === treeItemType.folder) {
          <md-icon class="!text-3xl">folder_closed</md-icon>
        } @else {
          <md-icon class="!text-3xl">hard_hat</md-icon>
        }
      </div>
    </td>
  </ng-container>

  <!-- name column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef class="text-small">Name</th>
    <td mat-cell *matCellDef="let item" class="text-small">
      <div class="flex flex-row">
        <div class="line-clamp-3 break-anywhere flex-auto">
          {{ item.name }}
        </div>
      </div>
    </td>
  </ng-container>

  <!-- size column -->
  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef class="text-small">Size</th>
    <td mat-cell *matCellDef="let item" class="text-small whitespace-nowrap">
      @if (item.type === treeItemType.file) {
        <span>{{ item.size | fileSize: 1 }}</span>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" class="!h-9"></tr>
  <tr
    mat-row
    *matRowDef="let item; columns: displayedColumns; let rowIndex = index"
    class="!h-12"
    [ngClass]="{
      '!bg-blue-pale': item.selected,
      'hover:!bg-white !cursor-default opacity-50': disabled(),
    }"
    (click)="itemClick(item)"
  ></tr>
</table>
