import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  input,
  output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusCheckboxModule, ModusIconModule } from '@trimble-gcs/modus';
import { FileSizePipe } from '@trimble-gcs/ngx-common';
import { ConnectTreeItem, ConnectTreeItemType } from '../connect-file-picker.models';

@UntilDestroy()
@Component({
  selector: 'sd-connect-file-browser-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusCheckboxModule,
    ModusIconModule,
    MatTableModule,
    FileSizePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './connect-file-browser-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectFileBrowserTableComponent {
  treeItems = input<ConnectTreeItem[]>([]);
  showPrevious = input(false);
  disabled = input(false);

  onPreviousClick = output();
  onFolderClick = output<ConnectTreeItem>();
  onSelectionChange = output<ConnectTreeItem[]>();

  dataSource = computed(() => {
    const sortedTreeItems = this.treeItems().sort(
      (a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name),
    );
    const data = this.showPrevious() ? [this.backTreeItem, ...sortedTreeItems] : sortedTreeItems;

    return new MatTableDataSource<ConnectTreeItem>(data);
  });

  private isAllSelected = computed(() => {
    const treeItems = this.treeItems().filter((item) => item.type === ConnectTreeItemType.file);
    return treeItems.length > 0 && treeItems.every((item) => item.selected);
  });

  readonly selectAllControl = new FormControl(false, { nonNullable: true });
  readonly treeItemType = ConnectTreeItemType;
  readonly displayedColumns = [
    'selected',
    'thumbnailUrl',
    'name',
    'modifiedBy',
    'modifiedOn',
    'size',
  ];

  private readonly backTreeItem: ConnectTreeItem = {
    regionUrl: '',
    versionId: '',
    type: ConnectTreeItemType.previous,
    id: '',
    name: '...',
    size: 0,
    modifiedBy: '',
    modifiedOn: new Date(),
    selected: false,
  };

  constructor() {
    this.createSelectAllEffect();
  }

  getItemId(index: number, item: ConnectTreeItem) {
    return item.id;
  }

  onItemClick(item: ConnectTreeItem) {
    if (this.disabled()) return;

    if (item.type === ConnectTreeItemType.previous) {
      this.onPreviousClick.emit();
    } else if (item.type === ConnectTreeItemType.folder) {
      this.onFolderClick.emit(item);
    } else if (item.type === ConnectTreeItemType.file) {
      this.onSelectionChange.emit([{ ...item, selected: !item.selected }]);
    }
  }

  toggleAllRows() {
    const selected = !this.isAllSelected();

    const items = this.treeItems()
      .filter((item) => item.type === ConnectTreeItemType.file)
      .map((item) => ({ ...item, selected }));

    this.onSelectionChange.emit(items);
  }

  checkboxKeyDown(treeItem: ConnectTreeItem) {
    treeItem.selected = !treeItem.selected;
    this.onSelectionChange.emit([treeItem]);
  }

  createSelectAllEffect() {
    effect(() => {
      this.selectAllControl.setValue(this.isAllSelected());
      if (this.disabled()) {
        this.selectAllControl.disable();
      } else {
        this.selectAllControl.enable();
      }
    });
  }
}
