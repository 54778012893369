import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusSwitchModule } from '@trimble-gcs/modus';
import { Scan3dStyle } from '../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-styling',
  standalone: true,
  imports: [ReactiveFormsModule, ModusSwitchModule, MatSliderModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-styling.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dStylingComponent {
  scan3dStyle = input.required<Scan3dStyle>();

  styleChange = output<Scan3dStyle>();

  showExperimental = false;

  formGroup = new FormGroup({
    showClassification: new FormControl<boolean>(false, { nonNullable: true }),
    showIntensity: new FormControl<boolean>(false, { nonNullable: true }),
    showEyeDomeLighting: new FormControl<boolean>(false, { nonNullable: true }),
    pointBudget: new FormControl<number>(10000000, { nonNullable: true }),
    pointDensity: new FormControl<number>(1, { nonNullable: true }),
    pointSize: new FormControl<number>(1, { nonNullable: true }),
  });

  private formValueChanges = toSignal(this.formGroup.valueChanges);

  constructor() {
    this.createScan3dStyleEffect();
    this.createFormValueChangesEffect();
  }

  showExperimentalClick(event: MouseEvent) {
    if (event.shiftKey && event.altKey) {
      this.showExperimental = !this.showExperimental;
    }
  }

  private createScan3dStyleEffect() {
    effect(() => {
      const style = this.scan3dStyle();

      // Using untracked because the MatSlider component
      // propagates the FormControl.setValue()
      // by setting a signal internally.
      untracked(() => {
        this.formGroup.setValue(
          {
            showClassification: style.showClassification,
            showIntensity: style.showIntensity,
            showEyeDomeLighting: style.showEyeDomeLighting,
            pointBudget: style.pointBudget,
            pointDensity: style.pointDensity,
            pointSize: style.pointSize,
          },
          { emitEvent: false },
        );
      });
    });
  }

  private createFormValueChangesEffect() {
    effect(() => {
      const changes = this.formValueChanges();
      const style = untracked(() => this.scan3dStyle());
      const mergedStyle: Scan3dStyle = { ...style, ...changes };

      this.styleChange.emit(mergedStyle);
    });
  }
}
