import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ConfigTab } from './config-tab';
import { SetActiveConfigTab } from './config.actions';

export interface ConfigStateModel {
  activeTab: ConfigTab;
}

const defaultState: ConfigStateModel = {
  activeTab: ConfigTab.Storage,
};

@State<ConfigStateModel>({
  name: 'configState',
  defaults: defaultState,
})
@Injectable()
export class ConfigState {
  @Selector() static activeTab(state: ConfigStateModel) {
    return state.activeTab;
  }

  @Action(SetActiveConfigTab) setActiveConfigTab(
    ctx: StateContext<ConfigStateModel>,
    action: SetActiveConfigTab,
  ) {
    ctx.patchState({ activeTab: action.tab });
  }
}
