import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { provideStore } from '@ngxs/store';
import { AppService } from './app-state/app.service';
import { AppState } from './app-state/app.state';
import { appRoutes } from './app.routes';
import { AuthState } from './auth/auth.state';
import { tokenInterceptor } from './auth/token.interceptor';
import { ClassificationSchemeState } from './classification/classification-scheme.state';
import { ClassificationState } from './classification/classification.state';
import { clientHeaderInterceptor } from './client-header/client-header.interceptor';
import { ConfigState } from './config/config.state';
import { CameraState } from './connect-3d-ext/camera/camera.state';
import { HOST3D_STATE_TOKEN, Host3dState } from './connect-3d-ext/host-3d.state';
import { ErrorState } from './error-handling/error.state';
import { ProjectSelectState } from './hosts/app-bar/project-select/project-select.state';
import { LocaleProvider } from './i18n/locale-provider';
import { LocaleState } from './i18n/locale.state';
import { ImportState } from './import/import.state';
import { LicenseState } from './license/license.state';
import { ConsoleLogger } from './logging/console-logger';
import { DataDogLogger } from './logging/data-dog-logger';
import { LOGGER_SERVICE_LOGGERS, LOGGER_SERVICE_NAME } from './logging/logger-types';
import { loggingInterceptor } from './logging/logging.interceptor';
import { BaseLayerState } from './map/base-layer/base-layer.state';
import { FeatureLayerState } from './map/feature-layer/feature-layer.state';
import { MapState } from './map/map.state';
import { DownloadState } from './options-panel/download/download.state';
import { OptionsPanelState } from './options-panel/options-panel.state';
import { RouteState } from './route/route.state';
import { Scan3dState } from './scan-3d-panel/scan-3d.state';
import { ScandataState } from './scandata/scandata.state';
import { ScannerTypeState } from './scanner-type/scanner-type.state';
import { WINDOW, windowProvider } from './services/window.provider';
import { StationState } from './station/station.state';
import { TilesetState } from './tileset/tileset.state';
import { UserState } from './user/user.state';
import { WorkflowState } from './workflow/workflow.state';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    provideRouter(appRoutes),
    provideHttpClient(
      withInterceptors([clientHeaderInterceptor, tokenInterceptor, loggingInterceptor]),
    ),
    provideStore(
      [
        AppState,
        AuthState,
        ScandataState,
        OptionsPanelState,
        BaseLayerState,
        FeatureLayerState,
        TilesetState,
        StationState,
        ClassificationSchemeState,
        ConfigState,
        Host3dState,
        Scan3dState,
        ScannerTypeState,
        MapState,
        DownloadState,
        ErrorState,
        CameraState,
        RouteState,
        LicenseState,
        ProjectSelectState,
        UserState,
        ImportState,
        WorkflowState,
        LocaleState,
        ClassificationState,
      ],
      withNgxsStoragePlugin({
        keys: [AuthState, Host3dState, RouteState, ProjectSelectState],
        afterDeserialize: (obj, key) => deserializeNgxsStorage(obj, key),
      }),
    ),
    importProvidersFrom(MatDialogModule),
    windowProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppService, WINDOW],
      multi: true,
    },
    { provide: LOGGER_SERVICE_NAME, useValue: 'LoggerService' },
    { provide: LOGGER_SERVICE_LOGGERS, useClass: ConsoleLogger, multi: true },
    { provide: LOGGER_SERVICE_LOGGERS, useClass: DataDogLogger, multi: true },
    { provide: LOCALE_ID, useClass: LocaleProvider },
  ],
};

function initializeAppFactory(appService: AppService, window: Window) {
  return () => appService.initialize(window);
}

function deserializeNgxsStorage(obj: any, key: string) {
  if (key === HOST3D_STATE_TOKEN.getName()) return Host3dState.deserialize(obj);
  return obj;
}
