<div class="flex flex-1 flex-col bg-gray-light p-4 pt-0 text-body">
  <!-- header -->
  <div class="flex flex-row items-center h-12 p-4 font-semibold">
    Translation Demo
    <div class="flex flex-1 justify-end">
      <!-- locales -->
      <mat-radio-group [formControl]="localeControl">
        @for (locale of locales; track locale) {
          <mat-radio-button [ngClass]="{ 'ml-8': !$first }" [value]="locale">{{
            locale
          }}</mat-radio-button>
        }
      </mat-radio-group>
    </div>
  </div>

  <!-- content -->
  <div class="flex-1 flex-col border border-gray-2 rounded px-4">
    <!-- static translated string -->
    <div class="border-b border-gray-2 py-4">
      <div class="font-semibold">Static translated string</div>
      <div class="py-1">{{ translations().static.text }}</div>
    </div>

    <!-- interpolation -->
    <div class="border-b border-gray-2 py-4">
      <div class="font-semibold">Interpolation</div>
      <div class="flex items-center">
        <modus-label class="mr-2">Name</modus-label>
        <div class="w-64 mr-2">
          <select modus-select [formControl]="userControl">
            @for (user of users; track user) {
              <option [ngClass]="{ 'ml-8': !$first }" [ngValue]="user">{{ user.name }}</option>
            }
          </select>
        </div>
        <div class="py-1">{{ greeting() }}</div>
      </div>
    </div>

    <!-- plural -->
    <div class="border-b border-gray-2 py-4">
      <div class="font-semibold">Plurals</div>
      <div>
        <mat-radio-group [formControl]="quantityControl">
          @for (quantity of quantities; track quantity) {
            <mat-radio-button [ngClass]="{ 'ml-4': !$first }" [value]="quantity">{{
              quantity
            }}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
      <div class="py-1">{{ quantityMessage() }}</div>
    </div>

    <!-- select -->
    <div class="border-b border-gray-2 py-4">
      <div class="font-semibold">Select</div>
      <div>
        <mat-radio-group [formControl]="modelControl">
          @for (model of models; track model) {
            <mat-radio-button [ngClass]="{ 'ml-4': !$first }" [value]="model">{{
              model
            }}</mat-radio-button>
          }
        </mat-radio-group>
      </div>
      <div class="py-1">{{ modelMessage() }}</div>
    </div>

    <!-- complex message -->
    <div class="py-4">
      <div class="font-semibold">Complex message</div>
      <div class="py-1 whitespace-pre-line">
        {{ complexMessage() }}
      </div>
    </div>

    <!-- pipes -->
    <!--
      short date, medium date, long date, etc
      number
      currency
     -->
  </div>
</div>
