import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnDestroy, viewChild } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { filter, map, Observable, switchMap } from 'rxjs';
import { SetPendingSave } from '../classification/classification.actions';
import { ClassificationState } from '../classification/classification.state';
import { DialogComponent } from '../dialog/dialog.component';
import { CANCEL_BUTTON, DialogData } from '../dialog/dialog.model';
import { DialogService } from '../dialog/dialog.service';
import { ClassificationComponent } from './classification/classification.component';
import { ConfigTab } from './config-tab';
import { SetActiveConfigTab } from './config.actions';
import { ConfigState } from './config.state';
import { StorageComponent } from './storage/storage.component';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, MatTabsModule, StorageComponent, ClassificationComponent],
  templateUrl: './config.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigComponent implements OnDestroy {
  private tabGroup = viewChild.required(MatTabGroup);

  private tabs = {
    [ConfigTab.Storage]: 0,
    [ConfigTab.Classification]: 1,
  };

  activeTab = toSignal(this.store.select(ConfigState.activeTab), { requireSync: true });
  selectedTabIndex = computed(() => this.tabs[this.activeTab()]);

  queryParams = toSignal(this.route.queryParams, { requireSync: true });
  showBackNavigation = computed(() => !!this.queryParams()['showBackNavigation']);

  private pendingSave = this.store.selectSignal(ClassificationState.pendingSave);
  private discardDialogRef?: MatDialogRef<DialogComponent, boolean>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private dialogService: DialogService,
  ) {
    this.addTabClickHandler();
  }

  ngOnDestroy(): void {
    this.discardDialogRef?.close();
  }

  viewScandata() {
    if (!this.pendingSave()) {
      this.router.navigate(['/']);
      return;
    }

    this.discardChanges().subscribe(() => this.router.navigate(['/']));
  }

  tabChange(event: MatTabChangeEvent) {
    this.setActiveTab(event.index);
  }

  private setActiveTab(index: number) {
    const tab = Object.keys(this.tabs)[index] as ConfigTab;
    this.store.dispatch(new SetActiveConfigTab(tab));
  }

  private addTabClickHandler() {
    toObservable(this.tabGroup).subscribe((tabGroup) => {
      // Material does not expose a way to cancel tab change other than overriding this private method.
      tabGroup._handleClick = (tab, header, index) => {
        if (this.selectedTabIndex() === index) return;
        if (!this.pendingSave()) return this.setActiveTab(index);

        this.discardChanges().subscribe(() => this.setActiveTab(index));
      };
    });
  }

  private discardChanges(): Observable<boolean> {
    const dialogData = new DialogData(
      'Unsaved Changes',
      'Do you want to discard your unsaved changes?',
      { text: 'Discard Changes', color: 'danger' },
      CANCEL_BUTTON,
    );

    this.discardDialogRef = this.dialogService.showMessage(dialogData);

    return this.discardDialogRef.afterClosed().pipe(
      filter((discard) => discard ?? false),
      switchMap(() => this.store.dispatch(new SetPendingSave(false))),
      map(() => true),
    );
  }
}
