import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
  Signal,
  computed,
  effect,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { FeatureFlagService } from '@trimble-gcs/feature-flags';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { debounceTime, filter, map, switchMap, take } from 'rxjs';
import { AppRoute } from '../../app-route';
import { AppState } from '../../app-state/app.state';
import { ClearClassificationSchemes } from '../../classification/classification-scheme.actions';
import { ConnectService } from '../../connect/connect.service';
import { DialogService } from '../../dialog/dialog.service';
import { ClearError } from '../../error-handling/error.actions';
import { FeatureFlagKey } from '../../feature-flags/feature-flag-key';
import { FilePickerService } from '../../import/file-pickers/file-picker.service';
import {
  ImportDialogComponent,
  ImportDialogResult,
  importDialogDefaultConfig,
} from '../../import/import-dialog.component';
import { ImportStatus } from '../../import/import.models';
import { ImportState } from '../../import/import.state';
import { noopErrorObserver } from '../../logging/noop-error-observer';
import { FeatureLayerService } from '../../map/feature-layer/feature-layer.service';
import { DownloadState } from '../../options-panel/download/download.state';
import { SetView } from '../../options-panel/options-panel.actions';
import { OptionsPanelComponent } from '../../options-panel/options-panel.component';
import { OptionsPanelView } from '../../options-panel/options-panel.models';
import { OptionsPanelState } from '../../options-panel/options-panel.state';
import { SetLastVisitedRoute } from '../../route/route.state';
import { getFiltersFromQueryParameter } from '../../scandata/scandata-query';
import { SelectOnly, SetFilters, SetTextFilter } from '../../scandata/scandata.actions';
import { ScandataService } from '../../scandata/scandata.service';
import { ScandataState } from '../../scandata/scandata.state';
import { Role } from '../../user/user.models';
import { UserState } from '../../user/user.state';
import { tabHostTranslations } from './tab-host.translations';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ModusFormFieldModule,
    ModusInputModule,
    MatToolbarModule,
    MatSidenavModule,
    MatTabsModule,
    OptionsPanelComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tab-host.component.html',
  styleUrls: ['./tab-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabHostComponent implements OnInit, OnDestroy {
  readonly translations = tabHostTranslations();

  // tab links
  appRoute = AppRoute;
  activeRoute: Signal<string> = this.getActiveRouteSignal();

  // text filter
  textFilter = this.store.selectSignal(ScandataState.textFilter);
  textFilterControl = new FormControl<string | null>(null);

  private textFilterControlValue = toSignal(this.textFilterControl.valueChanges, {
    initialValue: null,
  });

  showTextFilterClear = computed(() => {
    const value = this.textFilterControlValue();
    return isDefined(value) && value.length > 0;
  });

  // options panel
  panelView = OptionsPanelView;
  private optionsPanelView = this.store.selectSignal(OptionsPanelState.view);
  showOptionsPanel = computed(() => this.optionsPanelView() !== OptionsPanelView.None);

  // filter button
  filterCount = this.store.selectSignal(ScandataState.filterCount);
  filterPanelActive = computed(() => this.optionsPanelView() === OptionsPanelView.ListFilters);

  // download button
  downloadCount = this.store.selectSignal(DownloadState.activeDownloadCount);

  downloadStatusPanelActive = computed(
    () => this.optionsPanelView() === OptionsPanelView.DownloadStatus,
  );

  private userRole = this.store.selectSignal(UserState.userRole);

  // settings button
  private settingsFeatureActive = toSignal(this.featureFlagService.hasFeature$('tools_settings'), {
    initialValue: false,
  });

  showSettings = computed(() => this.userRole() === Role.Admin && this.settingsFeatureActive());

  // import button
  private importDialogRef: MatDialogRef<ImportDialogComponent, ImportDialogResult> | null = null;
  private importFeatureActive = toSignal(this.featureFlagService.hasFeature$('tools_import'), {
    initialValue: false,
  });

  private importPickers = toSignal(
    this.filePickerService
      .getFilePickers()
      .pipe(map((pickers) => pickers.filter((picker) => picker.enabled))),
    {
      initialValue: [],
    },
  );

  showImport = computed(
    () =>
      this.userRole() === Role.Admin &&
      this.importFeatureActive() &&
      this.importPickers().length > 0,
  );

  private importStatus = this.store.selectSignal(ImportState.status);
  importError = computed(() => this.importStatus() === ImportStatus.Error);
  importCompleted = computed(() => this.importStatus() === ImportStatus.Completed);
  importCount = this.store.selectSignal(ImportState.activeImportFileCount);

  constructor(
    private router: Router,
    private scandataService: ScandataService,
    private featureLayerService: FeatureLayerService,
    private connectService: ConnectService,
    private store: Store,
    private dialogService: DialogService,
    private featureFlagService: FeatureFlagService<FeatureFlagKey>,

    private filePickerService: FilePickerService,
  ) {
    this.createActiveRouteEffect();
    this.selectScandataFromQueryParams();
    this.setFiltersFromQueryParams();
  }

  ngOnInit() {
    this.setInitialTextFilterValue();
    this.subscribeToTextFilterChange();
  }

  ngOnDestroy() {
    this.importDialogRef?.close();
  }

  navigateClick(route: AppRoute) {
    this.router.navigate([route]);
  }

  toggleOptionsPanelViewClick(view: OptionsPanelView) {
    this.store.dispatch(new SetView(view, true));
  }

  refreshClick() {
    this.refreshScansAndFeatures().subscribe(noopErrorObserver);
  }

  view3DClick() {
    this.connectService.goTo3dExtension();
  }

  viewSettingsClick() {
    this.router.navigate([AppRoute.Config], { queryParams: { showBackNavigation: true } });
  }

  clearTextFilterClick() {
    this.textFilterControl.reset();
  }

  importClick() {
    this.importDialogRef = this.dialogService.showComponent<
      ImportDialogComponent,
      any,
      ImportDialogResult
    >(ImportDialogComponent, importDialogDefaultConfig);

    this.importDialogRef
      .afterClosed()
      .pipe(
        filter((result) => result?.reloadScans === true),
        switchMap(() => this.refreshScansAndFeatures()),
      )
      .subscribe();
  }

  private getActiveRouteSignal() {
    const activeroute$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        const url = event.urlAfterRedirects;
        return url.startsWith('/') ? url.slice(1) : url;
      }),
    );
    return toSignal(activeroute$, { initialValue: '' });
  }

  private createActiveRouteEffect() {
    effect(() => {
      const path = this.activeRoute();
      const rememberRoutes = [AppRoute.MapView, AppRoute.ListView];
      if (!rememberRoutes.map((route) => route.toString()).includes(path)) return;
      this.store.dispatch(new SetLastVisitedRoute(path as AppRoute));
    });
  }

  private refreshScansAndFeatures() {
    return this.store.dispatch([new ClearError('scanLoadError'), ClearClassificationSchemes]).pipe(
      switchMap(() => this.scandataService.refreshScandata()),
      switchMap(() => this.featureLayerService.loadFeatures()),
    );
  }

  private setInitialTextFilterValue() {
    this.store.selectOnce(ScandataState.textFilter).subscribe((value) => {
      this.textFilterControl.reset(value);
    });
  }

  private subscribeToTextFilterChange() {
    this.textFilterControl.valueChanges
      .pipe(debounceTime(250), untilDestroyed(this))
      .subscribe((value) => {
        this.store.dispatch(new SetTextFilter(value ?? undefined));
      });
  }

  private selectScandataFromQueryParams() {
    this.store
      .select(ScandataState.scandata)
      .pipe(
        filter((scans) => scans.length > 0),
        take(1),
        switchMap(() => this.store.selectOnce(AppState.urlQueryScandataIds)),
        filter((scandataIds) => scandataIds.length > 0),
        switchMap((scandataIds) => this.store.dispatch(new SelectOnly(scandataIds))),
        untilDestroyed(this),
      )
      .subscribe();
  }

  private setFiltersFromQueryParams() {
    const scandataQuery = this.store.selectSnapshot(AppState.urlQueryScandataQuery);
    if (isNil(scandataQuery)) return;

    const filters = getFiltersFromQueryParameter(scandataQuery);
    this.store.dispatch(new SetFilters(filters));
  }
}
