import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { combineLatest } from 'rxjs';
import { ClassificationState } from '../classification/classification.state';
import { ImportState } from '../import/import.state';
import { DownloadState } from '../options-panel/download/download.state';
import { WorkflowState } from '../workflow/workflow.state';
import { ConnectService } from './connect.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectExplorerService {
  constructor(
    private connectService: ConnectService,
    private store: Store,
  ) {}

  subscribeForClosingPrompt() {
    combineLatest([
      this.store.select(DownloadState.activeDownloadCount),
      this.store.select(ImportState.activeImportFileCount),
      this.store.select(WorkflowState.tiling),
      this.store.select(ClassificationState.pendingSave),
    ]).subscribe(([downloading, uploading, tiling, pendingSave]) => {
      const workflowCount = downloading + uploading + tiling;

      if (workflowCount > 0) {
        const prompt = this.getClosingPrompt(downloading, uploading, tiling);
        this.connectService.enableClosingPrompt(prompt);
      } else if (pendingSave) {
        this.connectService.enableClosingPrompt(
          'You have unsaved changes. Are you sure you want to discard them?',
        );
      } else {
        this.connectService.disableClosingPrompt();
      }
    });
  }

  private getClosingPrompt(downloading: number, uploading: number, tiling: number) {
    const processes: { count: number; description: string }[] = [];

    if (downloading > 0) processes.push({ count: downloading, description: 'download' });
    if (uploading > 0) processes.push({ count: uploading, description: 'upload' });
    if (tiling > 0) processes.push({ count: tiling, description: 'tiling' });

    let pluralProcess = false;

    const processDescription = processes.reduce((prev: string | null, cur, index) => {
      const lastItem = processes.length - 1 === index;
      const delimiter = isNil(prev) ? '' : lastItem ? ' and ' : ', ';

      pluralProcess = cur.count > 1;

      return `${prev ?? ''}${delimiter}${cur.count} ${cur.description}`;
    }, null);

    return (
      `You have ${processDescription} ${pluralProcess ? 'processes' : 'process'} running. \n` +
      `Are you sure you want to discard ${pluralProcess || processes.length > 1 ? 'these processes' : 'this process'}?`
    );
  }
}
