import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusInputModule,
  ModusMenuModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { AutoFocusDirective } from '@trimble-gcs/ngx-common';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs';
import { ErrorState } from '../../error-handling/error.state';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from '../../scandata-list/scandata-empty/scandata-empty.component';
import { ScandataSortMenuComponent } from '../../scandata-list/scandata-sort-menu/scandata-sort-menu.component';
import { ScandataTreeViewComponent } from '../../scandata-tree/scandata-tree-view/scandata-tree-view.component';
import { SortInfo } from '../../scandata/scandata-query.models';
import { sortScandata } from '../../scandata/scandata-sort';
import { SetSortInfo, SetTextFilter } from '../../scandata/scandata.actions';
import { ScandataModel } from '../../scandata/scandata.models';
import { ScandataState } from '../../scandata/scandata.state';
import { ClearCurrentStation } from '../../station/station.actions';
import { StationDisplayStatus } from '../../station/station.models';
import { StationState } from '../../station/station.state';
import { Scan3dListFilterOption } from '../models/scan-3d-list-filter-option';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-list',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusFormFieldModule,
    ModusSelectModule,
    ModusButtonModule,
    ModusIconModule,
    ModusInputModule,
    ModusMenuModule,
    ModusTooltipModule,
    ScandataTreeViewComponent,
    ScandataSortMenuComponent,
    ScandataEmptyComponent,
    ScrollingModule,
    MatSelectModule,
    MatProgressBarModule,
    AutoFocusDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dListComponent {
  // The store select data crosses component bounds and is kept in state.
  // It is set up at the entry point into the 3D extension and consumed here.

  public quotaExceeded = input(false);

  public settingsClick = output<void>();
  public modelDetailClick = output<ScandataModel>();

  private currentStation = toSignal(this.store.select(StationState.currentStation));
  public currentStationName = computed(() => this.currentStation()?.station?.name ?? '');

  public hasCurrentStation = computed(
    () => this.currentStation()?.displayStatus === StationDisplayStatus.Displayed,
  );

  public isLoading = toSignal(this.store.select(ScandataState.isLoading), { initialValue: false });

  public scanLoadError = toSignal(this.store.select(ErrorState.hasError('scanLoadError')), {
    initialValue: false,
  });

  public data = computed(() => {
    const scans = this.filteredScandata().filter(
      (x) => this.filterOptionControlValue() === Scan3dListFilterOption.ShowAll || x.selected,
    );

    const sortInfo = this.sortInfo();
    return sortScandata(scans, sortInfo.sortBy, sortInfo.sortDirection);
  });

  public filterOptionControl = new FormControl<Scan3dListFilterOption>(
    Scan3dListFilterOption.ShowAll,
  );

  private filterOptionControlValue = toSignal(this.filterOptionControl.valueChanges, {
    initialValue: null,
  });

  private filteredScandata = toSignal(this.store.select(ScandataState.textFilteredScandata), {
    initialValue: [],
  });

  public scan3dListFilterOption = Scan3dListFilterOption;

  private textFilter = toSignal(this.store.select(ScandataState.textFilter));
  public showTextFilter = signal(false);
  public textFilterControl = new FormControl<string | null>(null);

  public sortInfo = toSignal(this.store.select(ScandataState.sortInfo), { requireSync: true });

  private filterCount = toSignal(this.store.select(ScandataState.filterCount), { initialValue: 0 });

  public scandataEmptyReason = computed(() =>
    !this.showScandataEmpty() ? ScandataEmptyReason.NoUploads : this.getScandataEmptyReason(),
  );

  public showScandataEmpty = computed(() =>
    this.isLoading() || this.scanLoadError() ? false : this.data().length === 0,
  );

  constructor(private store: Store) {
    this.subscribeToSelected();
    this.subscribeToTextFilterChanges();
  }

  hideTextFilterClick() {
    this.showTextFilter.set(false);
    this.textFilterControl.reset();
  }

  onExitStationClick() {
    this.store.dispatch(new ClearCurrentStation());
  }

  onModelDetailClick(model: ScandataModel) {
    this.modelDetailClick.emit(model);
  }

  onSettingClick() {
    this.settingsClick.emit();
  }

  showTextFilterClick() {
    this.showTextFilter.set(true);
  }

  sortClick(sortInfo: SortInfo) {
    this.store.dispatch(new SetSortInfo(sortInfo));
  }

  private getScandataEmptyReason() {
    const filterCount = this.filterCount();
    const textFilter = this.textFilter();

    if (filterCount > 0 || isDefined(textFilter)) return ScandataEmptyReason.NoFilterResults;

    if (this.filterOptionControlValue() === Scan3dListFilterOption.ShowSelected)
      return ScandataEmptyReason.NoSelected;

    return ScandataEmptyReason.NoUploads;
  }

  private subscribeToSelected() {
    this.store
      .select(ScandataState.selected)
      .pipe(
        distinctUntilChanged((prev, curr) => prev.length === curr.length),
        map((selected) => {
          return selected.length > 0
            ? Scan3dListFilterOption.ShowSelected
            : Scan3dListFilterOption.ShowAll;
        }),
        untilDestroyed(this),
      )
      .subscribe((option) => {
        return this.filterOptionControl.setValue(option);
      });
  }

  private subscribeToTextFilterChanges() {
    this.textFilterControl.valueChanges
      .pipe(
        debounceTime(250),
        switchMap((textFilter) => this.store.dispatch(new SetTextFilter(textFilter ?? undefined))),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
