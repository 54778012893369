export function isDefined<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export function isNil(value: unknown): value is undefined | null {
  return value === undefined || value === null;
}

export function isNumeric(value: number | string | undefined | null): boolean {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number#number_coercion

  // Numbers are returned as-is. (We also exclude Infinity, -Infinity and NaN)
  if (typeof value === 'number') return Number.isFinite(value);

  // undefined turns into NaN.
  // null turns into 0.
  if (isNil(value)) return false;

  // Empty or whitespace-only strings are converted to 0.
  if (/^\s*$/.test(value)) return false;

  const coerced = +value;
  return typeof coerced === 'number' && Number.isFinite(coerced);
}

export function isString(value: unknown): value is string {
  const type = typeof value;
  if (type === 'string') return true;

  return (
    type === 'object' &&
    isDefined(value) &&
    !Array.isArray(value) &&
    getObjTag(value) === '[object String]'
  );
}

export function camelCase(word: string): string {
  return word.charAt(0).toLowerCase() + word.slice(1);
}

export function pascalCase(word: string): string {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function pick<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
  const picked = keys
    .filter((key) => key in obj)
    .reduce(
      (result, key) => {
        result[key] = obj[key];
        return result;
      },
      {} as Pick<T, K>,
    );
  return picked;
}

export function omit<T extends object, K extends keyof T>(obj: T, ...keys: K[]): Omit<T, K> {
  const omitted = Object.keys(obj)
    .filter((key) => keys.indexOf(key as K) < 0)
    .reduce(
      (result, key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (result as any)[key] = obj[key as K];
        return result;
      },
      {} as Omit<T, K>,
    );
  return omitted;
}

export function matchKeys<T, U extends object>(obj: T, other: U): Array<keyof T> {
  const keys = new Array<keyof T>();
  for (const key in obj) {
    if (key in other) keys.push(key);
  }
  return keys;
}

export function isKeyOfEnum<T extends object>(target: T) {
  return (token: unknown): token is T[keyof T] =>
    Object.values(target).includes(token as T[keyof T]);
}

export function buildUrl(base: string, pathAndQuerystring: string) {
  const trailingForwardSlash = new RegExp(/\/+$/);
  const cleanBase = base.replace(trailingForwardSlash, '');

  const leadingForwardSlash = new RegExp(/^\/+/);
  const cleanPathAndQuerystring = pathAndQuerystring.replace(leadingForwardSlash, '');

  return `${cleanBase}/${cleanPathAndQuerystring}`;
}

// Helper function, not export with common API.
export function getObjTag(obj: unknown): string {
  return obj === null
    ? obj === undefined
      ? '[object Undefined]'
      : '[object Null]'
    : toString.call(obj);
}
