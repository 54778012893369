import { inject, InjectionToken } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ScandataModel } from './scandata.models';
import { ScandataState } from './scandata.state';

export const TOGGLE_SELECTED_SCANS = new InjectionToken<
  (scans: ScandataModel[], scan: ScandataModel, event: MouseEvent) => ScandataModel[]
>('ToggleSelectedScans', {
  factory: () => {
    const chronologicalSelected = inject(Store).selectSignal(ScandataState.chronologicalSelected);

    return (scans: ScandataModel[], scan: ScandataModel, event: MouseEvent) => {
      if (event.shiftKey) {
        const lastSelectedId = chronologicalSelected().at(-1)?.id ?? scans.at(0)?.id;
        return isNil(lastSelectedId) ? [] : toggleRangeSelected(scans, scan, lastSelectedId);
      }

      const checkboxClicked = isEventFromModusCheckbox(event);
      if (event.ctrlKey || checkboxClicked) return toggleScanSelect(scan);

      return toggleSingleSelected(scans, scan);
    };
  },
});

function toggleScanSelect(scan: ScandataModel) {
  scan.selected = !scan.selected;
  return [scan];
}

function toggleRangeSelected(scans: ScandataModel[], scan: ScandataModel, lastSelectedId: string) {
  const selectedIndex = scans.findIndex((item) => item.id === scan.id);
  const prevSelectedIndex = scans.findIndex((item, index) => {
    return item.id === lastSelectedId ? index : null;
  });

  const startIndex = selectedIndex < prevSelectedIndex ? selectedIndex : prevSelectedIndex;
  const endIndex = selectedIndex > prevSelectedIndex ? selectedIndex : prevSelectedIndex;

  const selection = scans.filter((_, index) => index >= startIndex && index <= endIndex);
  if (selectedIndex < prevSelectedIndex) selection.reverse();

  const selected = !scan.selected;
  const changedScans = selection.map((scan) => <ScandataModel>{ ...scan, selected });

  return changedScans;
}

function toggleSingleSelected(scans: ScandataModel[], scan: ScandataModel) {
  const currentSelection = scans.filter((item) => item.selected);
  const inCurrentSelection = currentSelection.find((item) => item.id === scan.id);

  currentSelection.forEach((item) => (item.selected = false));

  //unselect scan if it's the only current selection
  scan.selected = currentSelection.length === 1 && inCurrentSelection ? false : true;

  if (!inCurrentSelection) currentSelection.push(scan);

  return currentSelection;
}

function isEventFromModusCheckbox(value: Event): boolean {
  const src = value.target;
  return isDefined(src) && 'tagName' in src && src.tagName === 'MODUS-CHECKBOX';
}
