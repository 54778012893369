export const mimimumIngestionSeconds = 60;
export const ingestionEstimateConfig: IngestionEstimateConfig[] = [
  {
    pointsMin: 0,
    pointsMax: 1000000,
    factor: 0.0000275901,
    minimum: 43.4228476262,
  },
  {
    pointsMin: 1000000,
    pointsMax: 10000000,
    factor: 0.00003,
    minimum: 0,
  },
  {
    pointsMin: 10000000,
    pointsMax: 100000000,
    factor: 0.0000083333,
    minimum: 121.6666666667,
  },
  {
    pointsMin: 100000000,
    pointsMax: 1000000000,
    factor: 5.152e-7,
    minimum: 531.1730419242,
  },
  {
    pointsMin: 1000000000,
    factor: 2.456e-7,
    minimum: 564.4547282512,
  },
];

export interface IngestionEstimateConfig {
  pointsMin: number;
  pointsMax?: number;
  factor: number;
  minimum: number;
}
