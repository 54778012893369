import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ScandataModel } from '../scandata/scandata.models';
import { ClearHost3dScandata, SetHost3dScandata } from './host-3d.actions';

export interface Host3dStateModel {
  scandata?: ScandataModel[];
}

export const HOST3D_STATE_TOKEN = new StateToken<Host3dStateModel>('host3dState');
const defaultState: Host3dStateModel = {};

@State<Host3dStateModel>({
  name: HOST3D_STATE_TOKEN,
  defaults: defaultState,
})
@Injectable()
export class Host3dState {
  @Selector() static scandata(state: Host3dStateModel): ScandataModel[] | undefined {
    return state.scandata;
  }

  @Action(SetHost3dScandata) setHost3dScandata(
    ctx: StateContext<Host3dStateModel>,
    { scandata }: SetHost3dScandata,
  ) {
    ctx.patchState({ scandata });
  }

  @Action(ClearHost3dScandata) clearHost3dScandata(ctx: StateContext<Host3dStateModel>) {
    ctx.patchState({ scandata: undefined });
  }

  static deserialize(model: Host3dStateModel) {
    const scandata = model.scandata;

    scandata?.forEach((scan) => {
      if (isDefined(scan.captureDate))
        scan.captureDate = new Date(scan.captureDate);

      if (isDefined(scan.uploadedDate)) scan.uploadedDate = new Date(scan.uploadedDate);
    });

    return model;
  }
}
