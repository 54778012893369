import { inject } from '@angular/core';
import { Route } from '@angular/router';
import { Store } from '@ngxs/store';
import { AppRoute } from './app-route';
import { authGuard } from './auth/auth.guard';
import { ConnectPermissionDeniedComponent } from './auth/connect-permission-denied/connect-permission-denied.component';
import { LoginComponent } from './auth/login/login.component';
import { LogoutComponent } from './auth/logout/logout.component';
import { NoProjectAccessComponent } from './auth/no-project-access/no-project-access.component';
import { ConfigComponent } from './config/config.component';
import { Host3dComponent } from './connect-3d-ext/host-3d.component';
import { DialogDemoComponent } from './dialog/demo/dialog-demo.component';
import { TabHostComponent } from './hosts/tab-host/tab-host.component';
import { TranslationDemoComponent } from './i18n/translation-demo/translation-demo.component';
import { LicenseCheckComponent } from './license/license-check/license-check.component';
import { licenseGuard } from './license/license.guard';
import { LoadingDemoComponent } from './loading/loading-demo/loading-demo.component';
import { MapComponent } from './map/map.component';
import { RouteState } from './route/route.state';
import { ScandataListComponent } from './scandata-list/scandata-list.component';

export const appRoutes: Route[] = [
  { path: AppRoute.Login, component: LoginComponent },
  { path: AppRoute.Logout, component: LogoutComponent },
  {
    path: AppRoute.Config,
    component: ConfigComponent,
    canActivate: [authGuard, licenseGuard],
  },
  { path: AppRoute.Connect3d, component: Host3dComponent, canActivate: [authGuard, licenseGuard] },
  { path: AppRoute.ConnectPermissionDenied, component: ConnectPermissionDeniedComponent },
  { path: AppRoute.NoProjectAccess, component: NoProjectAccessComponent },
  { path: AppRoute.LicenseCheck, component: LicenseCheckComponent, canActivate: [authGuard] },
  {
    path: '',
    component: TabHostComponent,
    canActivate: [authGuard, licenseGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: () => inject(Store).selectSnapshot(RouteState.lastVisitedRoute),
      },
      {
        path: AppRoute.MapView,
        component: MapComponent,
      },
      {
        path: AppRoute.ListView,
        component: ScandataListComponent,
      },
    ],
  },
  { path: 'loading-demo', component: LoadingDemoComponent },
  { path: 'dialog-demo', component: DialogDemoComponent },
  { path: 'translation-demo', component: TranslationDemoComponent },
  { path: '**', redirectTo: '' },
];
