import { TranslationMap } from '../../i18n/translation-types';

export function noProjectAccessTranslations() {
  return {
    noProjectAccess: {
      text: $localize`:@@auth.noProjectAccess:You don't have access to this project.`,
      desc: 'Message to user that they do not have permission to access the project.',
    },
  } satisfies TranslationMap;
}
