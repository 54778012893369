import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnDestroy,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { combineLatest, filter, take } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../../../app-state/app.state';
import { ConnectRegionService } from '../../../connect/connect-region.service';
import { ConnectRegion } from '../../../connect/connect.models';
import {
  ProjectSelectDialogComponent,
  projectSelectDialogDefaultConfig,
  ProjectSelectDialogResult,
} from '../../../connect/project-select-dialog/project-select-dialog.component';
import { DialogService } from '../../../dialog/dialog.service';
import { LoadingService } from '../../../loading/loading.service';
import { ImportFile } from '../../import.models';
import { ConnectFileBrowserComponent } from './connect-file-browser/connect-file-browser.component';
import { ConnectTreeItem } from './connect-file-picker.models';
import { ConnectFilePickerService } from './connect-file-picker.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ConnectFileBrowserComponent,
    ModusFormFieldModule,
    ModusTooltipModule,
    ModusIconModule,
    ModusButtonModule,
    MatDialogModule,
    MatProgressBarModule,
  ],
  templateUrl: './connect-file-picker.component.html',
  styles: [
    `
      :host {
        display: flex;
        flex: 1 1 auto;
        height: 100%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectFilePickerComponent implements OnDestroy {
  private readonly store = inject(Store);
  private readonly dialogService = inject(DialogService);
  private readonly loadingService = inject(LoadingService);
  private readonly connectRegionService = inject(ConnectRegionService);
  private readonly connectFilePickerService = inject(ConnectFilePickerService);

  selectedRegion = signal<ConnectRegion | undefined>(undefined);
  selectedProject = signal<ConnectProject | undefined>(undefined);
  selectedProjectName = computed(() => this.selectedProject()?.name);

  selectedItems = signal<ConnectTreeItem[]>([]);
  selectedSummary = computed(() => this.getSelectedSummary());
  selectedSummaryTooltip = computed(() =>
    this.selectedItems()
      .map((item) => item.name)
      .join('\n'),
  );

  isLoading = toSignal(this.loadingService.isLoading$(this));

  disableCancel = signal(false);
  disablePicking = computed(() => this.isLoading() || this.disableCancel());
  disableNext = computed(() => this.disableCancel() || this.selectedItems().length === 0);

  projectPickerDialogRef:
    | MatDialogRef<ProjectSelectDialogComponent, ProjectSelectDialogResult>
    | undefined;

  constructor(private dialogRef: MatDialogRef<ConnectFilePickerComponent, ImportFile[]>) {
    this.selectDefaultProject();
  }

  ngOnDestroy() {
    this.projectPickerDialogRef?.close();
  }

  showProjectPicker() {
    this.projectPickerDialogRef = this.dialogService.showComponent<
      ProjectSelectDialogComponent,
      any,
      ProjectSelectDialogResult
    >(ProjectSelectDialogComponent, projectSelectDialogDefaultConfig);

    this.projectPickerDialogRef
      .afterClosed()
      .pipe(filter(isDefined))
      .subscribe((result) => {
        this.selectedRegion.set(result.region);
        this.selectedProject.set(result.project);
      });
  }

  setLoading(loading: boolean) {
    if (loading) this.loadingService.startLoading(this);
    else this.loadingService.stopLoading(this);
  }

  selectionChange(changedTreeItems: ConnectTreeItem[]) {
    const selectedItems = this.selectedItems().filter((currentSelectedItem) => {
      // remove items no longer selected
      const changedItem = changedTreeItems.find((item) => item.id === currentSelectedItem.id);
      return isNil(changedItem) ? true : changedItem.selected;
    });

    const addItems = changedTreeItems.filter(
      (changedItem) =>
        changedItem.selected && isNil(selectedItems.find((item) => item.id === changedItem.id)),
    );

    const newSelectedItems = [...selectedItems, ...addItems];
    this.selectedItems.set(newSelectedItems);
  }

  cancelClick() {
    this.dialogRef.close();
  }

  nextClick() {
    this.loadingService.startLoading(this);
    this.disableCancel.set(true);

    this.connectFilePickerService
      .mapToImportFiles(this.selectedItems())
      .subscribe((importFiles) => this.dialogRef.close(importFiles));
  }

  private selectDefaultProject() {
    const regions$ = this.connectRegionService.getConnectRegions();
    const project$ = this.store.select(AppState.project).pipe(filter(isDefined));

    combineLatest([regions$, project$])
      .pipe(take(1))
      .subscribe(([regions, project]) => {
        const region = regions.find((region) => region.location === project.location)!;
        this.selectedRegion.set(region);
        this.selectedProject.set(project);
      });
  }

  private getSelectedSummary() {
    const selectedCount = this.selectedItems().length;
    if (selectedCount === 0) return;

    return `${selectedCount} file${selectedCount > 1 ? 's' : ''} selected`;
  }
}
