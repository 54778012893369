import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isDefined } from '@trimble-gcs/common';
import { ModusIconModule } from '@trimble-gcs/modus';
import { combineLatest, combineLatestWith, filter, map, Observable, switchMap, tap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { ConnectProjectService } from '../../../../connect/connect-project.service';
import { ConnectRegion } from '../../../../connect/connect.models';
import { ConnectTreeItem } from '../connect-file-picker.models';
import { ConnectFilePickerService } from '../connect-file-picker.service';
import { Breadcrumb, ConnectFileBreadcrumbComponent } from './connect-file-breadcrumb.component';
import { ConnectFileBrowserTableComponent } from './connect-file-browser-table.component';

@UntilDestroy()
@Component({
  selector: 'sd-connect-file-browser',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ConnectFileBreadcrumbComponent,
    ConnectFileBrowserTableComponent,
  ],
  templateUrl: './connect-file-browser.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectFileBrowserComponent {
  private readonly connectFilePickerService = inject(ConnectFilePickerService);
  private readonly connectProjectService = inject(ConnectProjectService);

  connectRegion = input<ConnectRegion>();
  connectProject = input<ConnectProject>();
  selectedItems = input<ConnectTreeItem[]>([]);
  disabled = input<boolean>(false);

  onLoading = output<boolean>();
  onSelectionChange = output<ConnectTreeItem[]>();

  breadcrumbs = signal<Breadcrumb[]>([]);
  rootFolderId = toSignal(this.getRootFolderId());

  private loading = signal(false);
  private items = toSignal(this.getTreeItems(), { initialValue: [] });
  treeItems = computed(() => (this.loading() ? [] : this.items()));

  showPrevious = computed(() => this.breadcrumbs().length > 1);

  constructor() {
    effect(() => this.onLoading.emit(this.loading()));

    // set initial value
    toObservable(this.rootFolderId)
      .pipe(filter(isDefined))
      .subscribe((folderId) => this.breadcrumbs.set([{ id: folderId, name: 'Explorer' }]));
  }

  previousClick() {
    const breadcrumbs = this.breadcrumbs().slice(0, -1);
    this.breadcrumbs.set(breadcrumbs);
  }

  breadcrumbClick(breadcrumb: Breadcrumb) {
    const breadcrumbs = this.breadcrumbs();
    const breadcrumbIndex = breadcrumbs.findIndex((i) => i.id === breadcrumb.id);
    const newBreadcrumbs = breadcrumbs.slice(0, breadcrumbIndex + 1);

    this.breadcrumbs.set(newBreadcrumbs);
  }

  folderClick(folder: ConnectTreeItem) {
    const breadcrumbs = [...this.breadcrumbs(), { id: folder.id, name: folder.name }];
    this.breadcrumbs.set(breadcrumbs);
  }

  private getRootFolderId() {
    return toObservable(this.connectProject).pipe(
      filter(isDefined),
      switchMap((connectProject) => this.connectProjectService.getProjectRootId(connectProject)),
    );
  }

  private getTreeItems(): Observable<ConnectTreeItem[]> {
    const region$ = toObservable(this.connectRegion).pipe(filter(isDefined));
    const currentFolderId$ = toObservable(this.breadcrumbs).pipe(
      map((breadcrumbs) => breadcrumbs.at(-1)),
      filter(isDefined),
      map((breadcrumb) => breadcrumb.id),
    );

    return combineLatest([region$, currentFolderId$]).pipe(
      tap(() => this.loading.set(true)),
      switchMap(([region, folderId]) =>
        this.connectFilePickerService.getTreeItems(region, folderId),
      ),
      combineLatestWith(toObservable(this.selectedItems)),
      map(([treeItems, selectedTreeItems]) => {
        return treeItems.map((treeItem) => {
          const selected = isDefined(selectedTreeItems.find((item) => item.id === treeItem.id));
          return { ...treeItem, selected };
        });
      }),
      tap(() => this.loading.set(false)),
    );
  }
}
