<div class="flex flex-col bg-white h-full">
  <div class="w-full relative">
    @if (isLoading()) {
      <div class="w-full absolute">
        <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
      </div>
    }
  </div>
  <div class="flex pl-3 pr-4 py-2 border-b border-gray-1">
    <div class="flex flex-col w-[210px] text-lg font-medium">
      Reality Capture Data
      <mat-select
        id="map-list-filter"
        class="modus-dropdown"
        panelClass="modus-dropdown-panel"
        [formControl]="filterOptionControl"
        disableRipple
      >
        <mat-option [value]="mapFilterOption.All">All project data</mat-option>
        <mat-option [value]="mapFilterOption.LocatedOnly">Georeferenced data only</mat-option>
      </mat-select>
    </div>

    <div class="flex flex-auto justify-end items-center">
      <sd-map-list-sort-menu
        [sortInfo]="sortInfo()"
        (sortChanged)="sortChanged.emit($event)"
      ></sd-map-list-sort-menu>
    </div>
  </div>

  @if (scanLoadError()) {
    <modus-alert
      class="w-full block p-4"
      message="Error loading data. Please try again later."
      type="error"
    ></modus-alert>
  }

  <div class="overflow-y-auto pt-1 pb-3" cdkScrollable>
    @for (item of scanList(); track item.scan.id) {
      <div
        class="flex flex-row items-center cursor-pointer hover:bg-gray-0 py-0.5 pl-[0.4rem] pr-2 select-none"
        (click)="onRowClick($event, item.scan)"
      >
        <modus-checkbox
          id="map-list-toggle-check"
          class="scale-[0.85]"
          [checked]="item.scan.selected"
          (keydown.enter)="onCheckboxKeyDown(item.scan)"
          (keydown.space)="onCheckboxKeyDown(item.scan); $event.preventDefault()"
        >
        </modus-checkbox>
        <div
          modus-tooltip="{{ item.scan.name }}"
          class="w-full overflow-hidden ml-[0.2rem] text-sm"
        >
          <div
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
            [ngClass]="{ 'text-red': item.hasError, 'text-gray-6': item.notReady }"
          >
            {{ item.scan.name }}
          </div>
          <div
            class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-small italic text-gray-6"
          >
            {{ item.message }}
          </div>
        </div>
        <button
          id="map-list-toggle-visible"
          modus-icon-button
          class="min-w-[1.75rem] text-gray-8 disabled:text-gray-6"
          [disabled]="!item.scan.hasFeature"
          (click)="changeItemVisibilityClicked.emit(item.scan); $event.stopPropagation()"
        >
          @if (item.scan.hiddenOnMap) {
            <md-icon class="!text-xl">visibility_off</md-icon>
          } @else {
            <md-icon class="!text-xl">visibility_on</md-icon>
          }
        </button>
        <button
          id="map-list-goto"
          modus-icon-button
          class="min-w-[1.75rem] text-gray-8 disabled:text-gray-6"
          [disabled]="!item.scan.hasFeature"
          (click)="zoomToItemClicked.emit(item.scan); $event.stopPropagation()"
        >
          <md-icon class="!text-xl">location</md-icon>
        </button>
      </div>
    }
    @if (showScandataEmpty()) {
      <sd-scandata-empty [reason]="scandataEmptyReason()"></sd-scandata-empty>
    }
  </div>
</div>
