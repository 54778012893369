<div class="flex h-full select-none">
  <sd-map-list
    [scanLoadError]="scanLoadError()"
    [isLoading]="isLoading()"
    [mapScandata]="mapScandata()"
    [filterOption]="filterOption()"
    [sortInfo]="sortInfo()"
    [showScandataEmpty]="showScandataEmpty()"
    [scandataEmptyReason]="scandataEmptyReason()"
    (changeItemVisibilityClicked)="changeItemVisibilityClicked($event)"
    (selectionChange)="selectionChange($event)"
    (zoomToItemClicked)="zoomToItemClicked($event)"
    (filterOptionChanged)="filterOptionChanged($event)"
    (sortChanged)="sortChanged($event)"
    class="w-[296px] max-[672px]:w-[240px] min-[1200px]:w-[312px]"
  ></sd-map-list>

  <sd-map-viewer
    #mapViewer
    [baseLayer]="activeBaseLayer()"
    [featureLayer]="featureLayer()"
    [bounds]="bounds()"
    [activeMapTool]="activeMapTool()"
    (mapToolSelected)="mapToolSelected($event)"
    (featureClicked)="featureClicked($event)"
    (featureDblClicked)="featureDblClicked($event)"
    (featuresSelected)="featuresSelected($event)"
    (boundsChanged)="boundsChanged($event)"
    class="flex-1 z-10"
  ></sd-map-viewer>

  <!-- toolbar -->
  <div
    class="pointer-events-none absolute block justify-center top-0 left-0 right-0 z-50 ml-[296px] max-[672px]:ml-[240px] min-[1200px]:ml-[312px]"
  >
    <div class="pointer-events-auto flex flex-wrap w-fit m-auto bg-white rounded-b-sm">
      <sd-map-toolbar
        [activeBaseLayer]="activeBaseLayer()"
        [baseLayers]="baseLayers()"
        [activeMapTool]="activeMapTool()"
        (baseLayerChange)="setActiveBaseLayer($event)"
        (mapToolSelected)="mapToolSelected($event)"
        (zoomInClick)="zoomIn()"
        (zoomOutClick)="zoomOut()"
        (fitToViewClick)="fitToView()"
        class="flex"
      ></sd-map-toolbar>
    </div>
  </div>
</div>
