import { TranslationMap } from '../../i18n/translation-types';

export function connectPermissionDeniedTranslations() {
  return {
    connectPermissionDenied: {
      title: {
        text: $localize`:@@auth.connectPermissionDenied.title:Extension Permission Denied`,
        desc: 'Title for the extension permission denied component.',
      },
      message: {
        text: $localize`:@@auth.connectPermissionDenied.message:This extension requires permission.`,
        desc: 'Message that the extension does not have the required permission.',
      },
      connect3d: {
        help: {
          text: $localize`:@@auth.connectPermissionDenied.connect3d.help:You can 'Reset permission' for this extension from the Settings menu.`,
          desc: 'Message for remedial action required when in 3D view.',
        },
      },
      connectExplorer: {
        help: {
          text: $localize`:@@auth.connectPermissionDenied.connectExplorer.help:You can 'Reset authorization' for this extension from the Settings > Extensions page.`,
          desc: 'Message for remedial action required when in explorer view.',
        },
        settings: {
          button: {
            text: $localize`:@@auth.connectPermissionDenied.connectExplorer.settings.button:Go To Settings > Extensions`,
            desc: "Text for the 'Go To Settings > Extensions' button.",
          },
        },
      },
    },
  } satisfies TranslationMap;
}
