import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { map, of, switchMap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { GET_CONNECT_REGION_URL } from './get-connect-region-url';
import { ConnectLicence } from './models/connect-license';

@Injectable({
  providedIn: 'root',
})
export class ConnectProjectService {
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);

  constructor(private http: HttpClient) {}

  getProjectRootId(project: ConnectProject) {
    /**
     * ConnectProject returned from the http endpoint contains a rootId property, but the
     * ConnectProject returned from the workspace API does not contain the property.
     *
     * Below we check if the ConnectProject has the rootId property. If not we fetch the
     * project from the http endpoint.
     */
    const hasRootId = (project: any): project is { rootId: string } => {
      return Object.hasOwnProperty.call(project, 'rootId');
    };

    if (hasRootId(project)) return of(project.rootId);

    return this.getConnectRegionUrl$(`projects/${project.id}`).pipe(
      switchMap((url) => this.http.get<{ rootId: string }>(url)),
      map((result) => result.rootId),
    );
  }

  getProjectLicence(project: ConnectProject) {
    return this.getConnectRegionUrl$(`projects/${project.id}/license`).pipe(
      switchMap((url) => this.http.get<ConnectLicence>(url)),
    );
  }

  getPrimaryAccount(project: ConnectProject) {
    return this.getProjectLicence(project).pipe(
      map((licence) => licence.accounts.find((acc) => acc.primary) ?? licence.accounts[0]),
    );
  }
}
