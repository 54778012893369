import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { buildUrl, isDefined, isNil } from '@trimble-gcs/common';
import { catchError, filter, firstValueFrom, from, map, of, switchMap } from 'rxjs';
import {
  Camera,
  CameraOptions,
  CameraPreset,
  ConnectFile,
  EventId,
  EventToArgMap,
  ObjectSelector,
  Viewer3DEmbedProperties,
  WorkspaceAPI,
  connect,
} from 'trimble-connect-workspace-api';
import { AppRoute } from '../app-route';
import { SetProject } from '../app-state/app.actions';
import { ClearAuth, SetConnectToken } from '../auth/auth.actions';
import { FitToView, SetHost3dScandata } from '../connect-3d-ext/host-3d.actions';
import { injectLogger } from '../logging/logger-injection';
import { ScandataState } from '../scandata/scandata.state';
import { ConnectWorkspace } from './connect.models';
import { GET_CONNECT_REGION_URL } from './get-connect-region-url';
import { ConnectContext } from './models/connect-context';
import { ConnectFileDownload } from './models/connect-file-download';

@Injectable({
  providedIn: 'root',
})
export class ConnectService {
  private readonly logger = injectLogger('ConnectService');
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);
  private _workspace!: ConnectWorkspace;
  private connectContext!: ConnectContext;
  private shouldEmit = false;

  constructor(
    private http: HttpClient,
    private store: Store,
    private actions$: Actions,
    private router: Router,
  ) {
    // Get this very early, so we know which extension is loaded
    // Also in Connect 3d viewer the explorer extension is also loaded with the 3d extension.
    this.connectContext = this.getConnectContext();
  }

  async getWorkspace(
    target?: Window | HTMLIFrameElement,
    timeout: number = 1000 * 30,
  ): Promise<ConnectWorkspace> {
    if (isNil(this._workspace) && isDefined(target)) {
      await this.initWorkspace(target, timeout);
    }
    return this._workspace;
  }

  goTo3dExtension() {
    this.store
      .selectOnce(ScandataState.scandata)
      .pipe(switchMap((scandata) => this.store.dispatch(new SetHost3dScandata(scandata))))
      .subscribe(async () => await this._workspace.api.extension.goTo('3dviewer'));
  }

  async goToConnect3dViewer(params?: Viewer3DEmbedProperties) {
    return await this._workspace.api.extension.goTo('3dviewer', params);
  }

  async enableClosingPrompt(prompt?: string) {
    await this._workspace.api.extension.preventClosing(prompt);
  }

  async disableClosingPrompt() {
    await this._workspace.api.extension.preventClosing();
  }

  async goToProjectSettings() {
    return await this._workspace.api.extension.goTo('settings-details');
  }

  async goToProjectExtensions() {
    return await this._workspace.api.extension.goTo('settings-extensions');
  }

  async getConnectToken() {
    try {
      var token = await this._workspace.api.extension.requestPermission('accesstoken');
      await this.handleConnectToken(token);
    } catch {
      this.logger.error(`Get Connect token error`);
      await firstValueFrom(this.store.dispatch(ClearAuth));
    }
  }

  getFileDownloadUrlForRegion(regionUrl: string, fileId: string, versionId?: string) {
    const versionQuery = versionId ? `?versionId=${versionId}` : '';
    const url = buildUrl(regionUrl, `files/fs/${fileId}/downloadurl${versionQuery}`);
    return this.http.get<ConnectFileDownload>(url);
  }

  getFileDownloadUrl(file: ConnectFile) {
    const versionQuery = file.versionId ? `?versionId=${file.versionId}` : '';
    return this.getConnectRegionUrl$(`files/fs/${file.id}/downloadurl${versionQuery}`).pipe(
      switchMap((url) => this.http.get<ConnectFileDownload>(url)),
    );
  }

  // We have consistently seen that workspace api setCamera method fails with a timeout.
  // We will try a few times and use a smaller timeout when creating the workspace.
  async setCamera(
    camera: Camera | ObjectSelector | 'reset' | CameraPreset,
    options?: Partial<CameraOptions>,
    retry: number = 2,
  ) {
    try {
      await this._workspace.api.viewer.setCamera(camera, options);
    } catch (error) {
      if (retry > 0) {
        this.logger.debug('Workspace api set camera failed', { camera, options, error });
        console.log('Workspace api set camera failed', { camera, options, error });
        this.setCamera(camera, options, retry - 1);
      }
    }
  }

  private async initWorkspace(target: Window | HTMLIFrameElement, timeout?: number | undefined) {
    const api = await this.createWorkspace(target, timeout);

    if (isWorkspaceAPI(api)) {
      this._workspace = new ConnectWorkspace(api);

      this.shouldEmit = await this.shouldEmitConnectEvents();

      await this.getConnectProject();
      this.subscribeToFitToView();

      this.logConnectEvents();
      this.subscribeToConnectTokenEvent();
      await this.getConnectToken();
    }
  }

  private async createWorkspace(
    target: Window | HTMLIFrameElement,
    timeout?: number | undefined,
  ): Promise<WorkspaceAPI> {
    const onEvent = this.onWorkspaceEvent.bind(this);
    return await connect(target, onEvent, timeout);
  }

  private onWorkspaceEvent<T extends EventId, U extends EventToArgMap[T]>(eventId: T, arg: U) {
    const data = arg?.data;
    const action = arg?.action;
    const event = { id: eventId, data, action };

    if (this._workspace && this.shouldEmit) {
      this._workspace?.['_event$'].next(event);
    }
  }

  private getConnectContext() {
    return window.location.href.includes('/connect-3d')
      ? ConnectContext.ThreeDViewer
      : ConnectContext.Explorer;
  }

  private async shouldEmitConnectEvents() {
    const is3dExtension = this.connectContext === ConnectContext.ThreeDViewer;
    const isConnect3dAvailable = await this.isConnect3dAvailable();
    return is3dExtension === isConnect3dAvailable;
  }

  private async getConnectProject() {
    const project = await this._workspace.api.project.getProject();
    this.store.dispatch(new SetProject(project));
    return project;
  }

  private logConnectEvents() {
    const logContext = this.connectContext === ConnectContext.Explorer ? 'explorer' : '3d';

    this._workspace.event$
      .pipe(filter((event) => event.id !== 'viewer.onCameraChanged')) // onCameraChanged is very busy
      .subscribe((event) =>
        this.logger.debug(`Connect ${logContext} event ${event.id}`, { event }),
      );
  }

  private subscribeToConnectTokenEvent() {
    /**
     * NOTE:
     * 'extension.accessToken' event fires when:
     * 1) The user clicks Deny|Allow from the Request Permissions dialog or 'Reset Authorization' for the extension.
     * 2) Connect refreshed the token and you've previously called requestPermission('accessToken')
     */
    this._workspace.event$
      .pipe(
        filter((event) => event.id === 'extension.accessToken'),
        map((event) => event.data as string),
        switchMap((token) => {
          return from(this.handleConnectToken(token)).pipe(
            catchError((err) =>
              of(this.logger.error(`Connect token event handle token error`, {}, err)),
            ),
          );
        }),
      )
      .subscribe();
  }

  private async handleConnectToken(token: string) {
    switch (token.toLowerCase()) {
      case 'denied':
        return await this.handleDeniedToken();

      case 'pending':
        return await this.handlePendingToken();

      case 'reset':
        return await this.getConnectToken();

      default:
        return await this.setToken(token);
    }
  }

  private async handleDeniedToken() {
    await this._workspace.api.extension.setStatusMessage('Requires permission');
    await firstValueFrom(this.store.dispatch(new ClearAuth()));

    //get the path, trimming leading slash
    const path = location.pathname.replace(/^\//, '');

    if (!path.includes(AppRoute.ConnectPermissionDenied)) {
      await this.router.navigate([AppRoute.ConnectPermissionDenied], {
        queryParams: { returnPath: path },
      });
    }
  }

  private async handlePendingToken() {
    await this._workspace.api.extension.setStatusMessage('');
    await firstValueFrom(this.store.dispatch(new ClearAuth()));
  }

  private async setToken(token: string) {
    await this._workspace.api.extension.setStatusMessage('');
    await firstValueFrom(this.store.dispatch(new SetConnectToken(token)));
  }

  private subscribeToFitToView() {
    this.actions$
      .pipe(
        ofActionDispatched(FitToView),
        map((action) => action.models.map((x) => ({ modelId: x.web3dId, objectRuntimeIds: [1] }))),
      )
      .subscribe((modelObjectIds) => this.setCamera({ modelObjectIds }));
  }

  private async isConnect3dAvailable() {
    // Currently only way to determine if Connect 3d viewer is available
    try {
      await this._workspace.api.viewer.getCameraMode();
      return true;
    } catch (error) {
      return false;
    }
  }
}

export function isWorkspaceAPI(arg: unknown): arg is WorkspaceAPI {
  return (arg as WorkspaceAPI).extension !== undefined;
}
