import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { buildUrl } from '@trimble-gcs/common';
import { catchError, forkJoin, map, Observable, of } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { ConnectRegion } from '../../../connect/connect.models';
import { ConnectService } from '../../../connect/connect.service';
import { ConnectFileDetail } from '../../../connect/models/connect-file-detail';
import { injectLogger } from '../../../logging/logger-injection';
import { PickerType } from '../../file-pickers/file-picker.models';
import { ImportFile, ImportFileStatus } from '../../import.models';
import { allowImportForFileType } from '../../import.service';
import { ConnectTreeItem, ConnectTreeItemType } from './connect-file-picker.models';

@Injectable({
  providedIn: 'root',
})
export class ConnectFilePickerService {
  private readonly logger = injectLogger('ConnectFilePickerService');
  private readonly http = inject(HttpClient);
  private readonly connectService = inject(ConnectService);

  getTreeItems(region: ConnectRegion, folderId: string): Observable<ConnectTreeItem[]> {
    const url = buildUrl(
      region.endpoint.url,
      `folders/${folderId}/items?tokenThumburl=true&sort=+name`,
    );
    return this.http
      .get<ConnectFileDetail[]>(url)
      .pipe(
        map((files) =>
          files
            .filter((file) => file.type === 'FOLDER' || allowImportForFileType(file.name))
            .map((file) => this.createTreeItem(region, file)),
        ),
      );
  }

  mapToImportFiles(treeItems: ConnectTreeItem[]): Observable<ImportFile[]> {
    const importFiles$ = treeItems.map((treeItem) => {
      return this.connectService
        .getFileDownloadUrlForRegion(treeItem.regionUrl, treeItem.id, treeItem.versionId)
        .pipe(
          map((downloadInfo) => {
            return {
              id: uuidv4(),
              name: treeItem.name,
              size: treeItem.size,
              pickerType: PickerType.Connect,
              downloadUrl: downloadInfo.url,
            } satisfies ImportFile;
          }),
          catchError((err) => {
            this.logger.error(`Error getting file download information from Connect`, {}, err);

            return of({
              id: uuidv4(),
              name: treeItem.name,
              size: treeItem.size,
              pickerType: PickerType.Connect,
              status: ImportFileStatus.Error,
              statusMessage: 'Error getting file information',
            } satisfies ImportFile);
          }),
        );
    });

    return forkJoin(importFiles$);
  }

  private createTreeItem(
    connectRegion: ConnectRegion,
    connectFileDetail: ConnectFileDetail,
  ): ConnectTreeItem {
    return {
      regionUrl: connectRegion.endpoint.url,
      versionId: connectFileDetail.versionId,
      type:
        connectFileDetail.type === 'FOLDER' ? ConnectTreeItemType.folder : ConnectTreeItemType.file,
      id: connectFileDetail.id,
      name: connectFileDetail.name,
      size: connectFileDetail.size,
      modifiedBy: connectFileDetail.modifiedBy,
      modifiedOn: new Date(connectFileDetail.modifiedOn),
      selected: false,
    };
  }
}
