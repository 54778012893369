import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  ElementRef,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModusCheckboxModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { FileSizePipe, PointCountPipe } from '@trimble-gcs/ngx-common';
import { debounceTime, map, shareReplay } from 'rxjs';
import {
  CHIP_HEIGHT,
  CHIP_MIN_WIDTH,
  ChipContainerComponent,
  ChipContainerSize,
} from '../../chip-container/chip-container.component';
import { ExternalFileId } from '../../connect/models/external-file-id';
import { IngestionProgressComponent } from '../../ingestion/ingestion-progress/ingestion-progress.component';
import { SortInfo } from '../../scandata/scandata-query.models';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';
import { TOGGLE_SELECTED_SCANS } from '../../scandata/toggle-selected-scans';
import { getResizeObservable } from '../../utils/resize-observable';

interface RowData {
  scan: ScandataModel;
  statusIcon: StatusIcon;
  thumbnail: Thumbnail;
  version: string | null;
}

interface StatusIcon {
  icon: string;
  color: string;
  message: string;
}

enum Thumbnail {
  None = 'None',
  Busy = 'Busy',
  Preview = 'Preview',
  Station = 'Station',
}

@Component({
  selector: 'sd-scandata-table',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    MatTableModule,
    MatSortModule,
    FileSizePipe,
    PointCountPipe,
    ChipContainerComponent,
    IngestionProgressComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTableComponent implements AfterViewInit {
  private readonly sort = viewChild.required<MatSort>(MatSort);
  private readonly toggleSelectedScans = inject(TOGGLE_SELECTED_SCANS);

  private readonly tagsColumnHeader =
    viewChild.required<ElementRef<HTMLElement>>('tagsColumnHeader');

  private readonly chipContainerHeight = 80;

  tagsContainerSize = signal<ChipContainerSize>({ height: 0, width: 0 });
  offscreenTagCount = signal<number>(0);

  readonly displayedColumns = [
    'selected',
    'thumbnailUrl',
    'name',
    'status',
    'uploadedBy',
    'uploadedDate',
    'captureDate',
    'pointCount',
    'fileSize',
    'tags',
  ];

  sortInfo = input.required<SortInfo>();
  data = input.required<ScandataModel[]>();

  dataSource = computed(() => {
    const data = this.data().map(
      (scan) =>
        ({
          scan,
          statusIcon: this.getIcon(scan),
          thumbnail: this.getThumbnail(scan),
          version: new ExternalFileId(scan.externalFileId).revisionLabel,
        }) satisfies RowData,
    );
    return new MatTableDataSource<RowData>(data);
  });

  selectionChange = output<ScandataModel[]>();
  chipClick = output<ScandataModel>();
  sortInfoChange = output<SortInfo>();

  isAllSelected = computed(() => {
    const data = this.data();
    return data.length > 0 && data.every((scan) => scan.selected);
  });

  isSomeSelected = computed(() => {
    const data = this.data();
    return data.some((scan) => scan.selected) && data.some((scan) => !scan.selected);
  });

  thumbnail = Thumbnail;
  status = PointcloudStatus;

  constructor() {
    this.createSortInfoEffect();
  }

  ngAfterViewInit(): void {
    this.sort().disableClear = true;
    this.subscribeTagsColumnResizeObserver();
  }

  getScanId(index: number, rowData: RowData) {
    return rowData.scan.id;
  }

  toggleAllRows() {
    const data = this.data();
    const selected = !this.isAllSelected();
    const changedScans = data.map((scan) => <ScandataModel>{ ...scan, selected });

    this.selectionChange.emit(changedScans);
  }

  onCheckboxKeyDown(rowData: RowData) {
    rowData.scan.selected = !rowData.scan.selected;
    this.selectionChange.emit([rowData.scan]);
  }

  onRowClick(mouseEvent: MouseEvent, rowData: RowData) {
    const toggledScans = this.toggleSelectedScans(this.data(), rowData.scan, mouseEvent);
    this.selectionChange.emit(toggledScans);
  }

  onChipClick(event: Event, rowData: RowData) {
    event.stopPropagation();
    this.chipClick.emit(rowData.scan);
  }

  onSortChange(sort: Sort) {
    const sortInfo = this.sortInfo();
    const sortChanged =
      sortInfo.sortBy !== sort.active || sortInfo.sortDirection !== sort.direction;
    if (!sortChanged) return;

    const changedSortInfo: SortInfo = {
      sortBy: <keyof ScandataModel>sort.active,
      sortDirection: sort.direction,
    };

    this.sortInfoChange.emit(changedSortInfo);
  }

  private getThumbnail(scan: ScandataModel) {
    if (scan.pointcloudStatus !== PointcloudStatus.Ready) return Thumbnail.Busy;

    const hasPreview = scan.thumbnailUrl?.length ?? 0 > 0;
    if (hasPreview) return Thumbnail.Preview;

    const hasStation = scan.numberOfStations > 0;
    if (hasStation) return Thumbnail.Station;

    return Thumbnail.None;
  }

  private getIcon(scan: ScandataModel) {
    switch (scan.pointcloudStatus) {
      case PointcloudStatus.Failed:
        return { icon: 'alert', color: 'text-red', message: 'Failed' };
      case PointcloudStatus.Ready:
        return { icon: 'check_circle', color: 'text-green', message: 'Ready' };
      case PointcloudStatus.Processing:
        return { icon: 'more_circle', color: 'text-trimble-yellow', message: 'Processing' };
      case PointcloudStatus.Uploading:
        return { icon: 'cloud_upload', color: 'text-gray-4', message: 'Uploading' };
      default:
        return { icon: '', color: 'text-trimble-gray', message: '' };
    }
  }

  private createSortInfoEffect() {
    effect(() => {
      const sortInfo = this.sortInfo();
      const sort = this.sort();

      if (sort.active === sortInfo.sortBy && sort.direction === sortInfo.sortDirection) return;

      sort.sort({
        id: sortInfo.sortBy,
        start: sortInfo.sortDirection,
        disableClear: true,
      });
    });
  }

  private subscribeTagsColumnResizeObserver() {
    /**
     * Due to the lack of paging, there could potentially be 100's chip-container components
     * within this component. To avoid the performance overhead of each chip-container
     * registering it's own resize observer, we are registering just one here and emitting
     * on a signal that the chip-container's parent element size has changed.
     */
    getResizeObservable(this.tagsColumnHeader().nativeElement)
      .pipe(
        debounceTime(200),
        map((observation) => {
          const entry = observation.entries[0];
          const { inlineSize } = entry.contentBoxSize[0];
          return { height: this.chipContainerHeight, width: inlineSize } as ChipContainerSize;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      )
      .subscribe({
        next: (size) => {
          const count = this.getOffscreentagCount(size);
          this.tagsContainerSize.set(size);
          this.offscreenTagCount.set(count);
        },
      });
  }

  private getOffscreentagCount(containerSize: ChipContainerSize) {
    const { height, width } = containerSize;
    const rows = Math.floor(height / CHIP_HEIGHT);
    const cols = Math.ceil(width / CHIP_MIN_WIDTH);
    return rows * cols;
  }
}
