<table
  mat-table
  matSort
  [dataSource]="dataSource()"
  [trackBy]="getScanId"
  (matSortChange)="onSortChange($event)"
  class="select-none"
>
  <!-- selected column -->
  <ng-container matColumnDef="selected">
    <th mat-header-cell *matHeaderCellDef class="w-4">
      <modus-checkbox
        id="list-toggle-check-all"
        [checked]="isAllSelected()"
        [indeterminate]="isSomeSelected()"
        (checkboxClick)="toggleAllRows()"
        (keydown.space)="$event.preventDefault()"
      >
      </modus-checkbox>
    </th>
    <td mat-cell *matCellDef="let row; let rowIndex = index">
      <modus-checkbox
        id="list-toggle-check"
        [checked]="row.scan.selected"
        (keydown.enter)="onCheckboxKeyDown(row)"
        (keydown.space)="onCheckboxKeyDown(row); $event.preventDefault()"
      ></modus-checkbox>
    </td>
  </ng-container>

  <!-- thumbnailUrl column -->
  <ng-container matColumnDef="thumbnailUrl">
    <th mat-header-cell *matHeaderCellDef class="min-w-[150px] max-w-[150px]"></th>
    <td mat-cell *matCellDef="let row" class="!py-1">
      <div
        class="h-[72px] w-[120px] rounded-lg border border-gray-2 flex items-center justify-center"
      >
        @switch (row.thumbnail) {
          @case (thumbnail.Busy) {
            <img src="../../../assets/hourglass_lightgrey_24dp.svg" class="h-[50%] w-[50%]" />
          }
          @case (thumbnail.Preview) {
            <img [src]="row.scan.thumbnailUrl" class="max-w-full max-h-full object-contain" />
          }
          @case (thumbnail.Station) {
            <img src="../../../assets/image_scene_lightgrey_24dp.svg" class="h-[55%] w-[55%]" />
          }
        }
      </div>
    </td>
  </ng-container>

  <!-- name column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="name" class="min-w-[228px]">Name</th>
    <td mat-cell *matCellDef="let row">
      <div class="flex flex-row">
        <div class="line-clamp-3 break-anywhere flex-auto">
          {{ row.scan.name }}
        </div>
        <div class="min-w-12 text-center my-auto">
          @if (row.version) {
            <modus-badge type="counter" color="tertiary">{{ row.version }}</modus-badge>
          }
        </div>
      </div>
    </td>
  </ng-container>

  <!-- status column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="status">Status</th>
    <td mat-cell *matCellDef="let row">
      @if (row.scan.pointcloudStatus === status.Processing) {
        <sd-ingestion-progress [scan]="row.scan" class="flex pl-3"></sd-ingestion-progress>
      } @else {
        <md-icon
          [modus-tooltip]="row.statusIcon.message"
          [ngClass]="row.statusIcon.color"
          class="pl-[10px] pt-1"
          >{{ row.statusIcon.icon }}</md-icon
        >
      }
    </td>
  </ng-container>

  <!-- uploadedBy column -->
  <ng-container matColumnDef="uploadedBy">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="uploadedBy" class="whitespace-nowrap">
      Uploaded By
    </th>
    <td mat-cell *matCellDef="let row">{{ row.scan.uploadedBy }}</td>
  </ng-container>

  <!-- uploadedDate column -->
  <ng-container matColumnDef="uploadedDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="uploadedDate" class="whitespace-nowrap">
      Upload Date
    </th>
    <td mat-cell *matCellDef="let row">
      <span class="block whitespace-nowrap">{{ row.scan.uploadedDate | date: 'mediumDate' }}</span>
      <span class="block whitespace-nowrap">{{ row.scan.uploadedDate | date: 'shortTime' }}</span>
    </td>
  </ng-container>

  <!-- captureDate column -->
  <ng-container matColumnDef="captureDate">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="captureDate" class="whitespace-nowrap">
      Capture Date
    </th>
    <td mat-cell *matCellDef="let row" class="whitespace-nowrap">
      {{ row.scan.captureDate | date: 'mediumDate' }}
    </td>
  </ng-container>

  <!-- pointCount column -->
  <ng-container matColumnDef="pointCount">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="pointCount">Points</th>
    <td mat-cell *matCellDef="let row" class="whitespace-nowrap">
      {{ row.scan.pointCount | pointCount: 3 }}
    </td>
  </ng-container>

  <!-- fileSize column -->
  <ng-container matColumnDef="fileSize">
    <th mat-header-cell *matHeaderCellDef mat-sort-header="fileSize">Size</th>
    <td mat-cell *matCellDef="let row" class="whitespace-nowrap">
      {{ row.scan.fileSize | fileSize: 1 }}
    </td>
  </ng-container>

  <!-- tags column -->
  <ng-container matColumnDef="tags">
    <th #tagsColumnHeader mat-header-cell *matHeaderCellDef class="min-w-52 w-[30%] max-w-[30%]">
      Tags
    </th>
    <td mat-cell *matCellDef="let row">
      @defer (on viewport) {
        <sd-chip-container
          [chips]="row.scan.tags"
          [containerSize]="tagsContainerSize()"
          [offscreenChipCount]="offscreenTagCount()"
          (chipClick)="onChipClick($event, row)"
          class="h-20"
        ></sd-chip-container>
      } @placeholder {
        <span></span>
      }
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr
    mat-row
    *matRowDef="let row; columns: displayedColumns"
    (click)="onRowClick($event, row)"
    [ngClass]="{ '!bg-blue-pale': row.scan.selected }"
  ></tr>
</table>
