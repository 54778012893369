import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { defaultLocale, LocaleId } from './locale-support';
import { SetLocale } from './locale.actions';

export interface LocaleStateModel {
  locale: LocaleId;
}

@State<LocaleStateModel>({
  name: 'localeState',
  defaults: {
    locale: defaultLocale,
  },
})
@Injectable()
export class LocaleState {
  @Selector() static getLocale(state: LocaleStateModel) {
    return state.locale;
  }

  @Action(SetLocale) setLocale(ctx: StateContext<LocaleStateModel>, action: SetLocale) {
    ctx.patchState({ locale: action.locale });
  }
}
