import { PointcloudStatus, ScandataModel } from './scandata.models';

export interface ScandataQuery {
  sortInfo: SortInfo;
  pageInfo: PageInfo;
  filters: Filters;
}

export interface SortInfo {
  sortBy: keyof ScandataModel;
  sortDirection: 'asc' | 'desc' | '';
}

export interface PageInfo {
  pageIndex: number;
  pageSize: number;
}

export const DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE = true;
export const NO_TAGS_FILTER = '-- No Tags --';
export const NO_SCANNER_TYPE_FILTER = '-- No Scanner Type --';

export interface Filters {
  name?: string;
  uploadFromDate?: Date;
  uploadToDate?: Date;
  captureFromDate?: Date;
  captureToDate?: Date;
  status?: PointcloudStatus[];
  scannerType?: string;
  tags?: string[];
  tagsMatchAll?: boolean;
  uploadedBy?: string[];
  isClassified?: true;
  containsStations?: true;
}
