import { DOCUMENT } from '@angular/common';
import { inject, Injectable, InjectionToken } from '@angular/core';
import { NgLocale } from './locale-support';

export const LOCALE_FILES = new InjectionToken<string>('LOCALE_FILES', {
  providedIn: 'root',
  factory: () => '/assets/locales',
});

@Injectable({
  providedIn: 'root',
})
export class LocaleLoader {
  private document = inject(DOCUMENT);
  private localeFiles = inject(LOCALE_FILES);

  private localeData = new Set<NgLocale>();

  loadLocaleData(ngLocale: NgLocale): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.localeData.has(ngLocale)) resolve();

      const script = this.document.createElement('script');
      script.src = `${this.localeFiles}/${ngLocale}.js`;

      script.onload = () => {
        this.localeData.add(ngLocale);
        resolve();
      };

      script.onerror = (error) => {
        reject(error);
      };

      this.document.body.appendChild(script);
    });
  }
}
