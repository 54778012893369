<div class="flex flex-col w-full gap-2">
  <sd-connect-file-breadcrumb
    [breadcrumbs]="breadcrumbs()"
    [disabled]="disabled()"
    (onBreadcrumbClick)="breadcrumbClick($event)"
  ></sd-connect-file-breadcrumb>

  <sd-connect-file-browser-table
    [treeItems]="treeItems()"
    [showPrevious]="showPrevious()"
    [disabled]="disabled()"
    (onPreviousClick)="previousClick()"
    (onFolderClick)="folderClick($event)"
    (onSelectionChange)="onSelectionChange.emit($event)"
    class="block flex-1 overflow-y-auto"
  ></sd-connect-file-browser-table>
</div>
