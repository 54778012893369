import { TranslationMap } from '../translation-types';

export function demoTranslations() {
  console.log('invoking demoTranslations()');
  return {
    static: {
      text: $localize`:@@demo.static:This string is in English.`,
      desc: 'Simple static string for demo.',
    },
    greeting: {
      text: $localize`:@@demo.greeting:Good day {name}.`,
      desc: 'Interpolated string for demo.',
    },
    plural: {
      '=0': {
        text: $localize`:@@demo.plural_zero:No stations selected.`,
        desc: 'Text to display when user has no stations selected.',
      },
      '=1': {
        text: $localize`:@@demo.plural_one:One station selected.`,
        desc: 'Text to display when user has one station selected.',
      },
      '=2': {
        text: $localize`:@@demo.plural_two:Two stations selected.`,
        desc: 'Text to display when user has two stations selected.',
      },
      other: {
        text: $localize`:@@demo.plural_other:{count} stations selected.`,
        desc: 'Text to display when user has more than two stations selected.',
      },
    },
    modelType: {
      station: {
        text: $localize`:@@demo.modelType_station:Station selected.`,
        desc: 'Text to display when user selected a station in the 3D Viewer.',
      },
      pointcloud: {
        text: $localize`:@@demo.modelType_pointcloud:Pointcloud selected.`,
        desc: 'Text to display when user selected a pointcloud in the 3D Viewer.',
      },
      other: {
        text: $localize`:@@demo.modelType_other:Model selected.`,
        desc: 'Text to display when user selected a model in the 3D Viewer.',
      },
    },
    complex: {
      user: {
        text: $localize`:@@demo.complex.user:The selected user is {name}. The locale is {locale}.`,
        desc: 'Interpolated string for demo.',
      },
      pronoun: {
        male: {
          text: $localize`:@@demo.complex.pronoun_male:He`,
          desc: 'Pronoun to use when gender is male, e.g. He has one model selected.',
        },
        female: {
          text: $localize`:@@demo.complex.pronoun_female:She`,
          desc: 'Pronoun to use when gender is female, e.g. She has one model selected.',
        },
        other: {
          text: $localize`:@@demo.complex.pronoun_other:They`,
          desc: 'Pronoun to use when gender is non-binary, e.g. They have one model selected.',
        },
      },
      conjugation: {
        male: {
          text: $localize`:@@demo.complex.conjugation_male:has`,
          desc: 'Conjugated form of "to have", determined by gender, e.g. He has one model selected.',
        },
        female: {
          text: $localize`:@@demo.complex.conjugation_female:has`,
          desc: 'Conjugated form of "to have", determined by gender, e.g. She has one model selected.',
        },
        other: {
          text: $localize`:@@demo.complex.conjugation_other:have`,
          desc: 'Conjugated form of "to have", determined by gender, e.g. They have one model selected.',
        },
      },
      modelType: {
        station: {
          '=0': {
            text: $localize`:@@demo.complex.modelType.station_zero:stations`,
            desc: 'Form of the term "station" when the quantity is zero.',
          },
          '=1': {
            text: $localize`:@@demo.complex.modelType.station_one:station`,
            desc: 'Form of the term "station" when the quantity is one.',
          },
          '=2': {
            text: $localize`:@@demo.complex.modelType.station_two:stations`,
            desc: 'Form of the term "station" when the quantity is two.',
          },
          other: {
            text: $localize`:@@demo.complex.modelType.station_other:stations`,
            desc: 'Form of the term "station" when the quantity is more than two.',
          },
        },
        pointcloud: {
          '=0': {
            text: $localize`:@@demo.complex.modelType.pointcloud_zero:pointclouds`,
            desc: 'Form of the term "pointcloud" when the quantity is zero.',
          },
          '=1': {
            text: $localize`:@@demo.complex.modelType.pointcloud_one:pointcloud`,
            desc: 'Form of the term "pointcloud" when the quantity is one.',
          },
          '=2': {
            text: $localize`:@@demo.complex.modelType.pointcloud_two:pointclouds`,
            desc: 'Form of the term "pointcloud" when the quantity is two.',
          },
          other: {
            text: $localize`:@@demo.complex.modelType.pointcloud_other:pointclouds`,
            desc: 'Form of the term "pointcloud" when the quantity is more than two.',
          },
        },
        other: {
          '=0': {
            text: $localize`:@@demo.complex.modelType.model_zero:models`,
            desc: 'Form of the term "model" when the quantity is zero.',
          },
          '=1': {
            text: $localize`:@@demo.complex.modelType.model_one:model`,
            desc: 'Form of the term "model" when the quantity is one.',
          },
          '=2': {
            text: $localize`:@@demo.complex.modelType.model_two:models`,
            desc: 'Form of the term "model" when the quantity is two.',
          },
          other: {
            text: $localize`:@@demo.complex.modelType.model_other:models`,
            desc: 'Form of the term "model" when the quantity is more than two.',
          },
        },
      },
      selection: {
        '=0': {
          text: $localize`:@@demo.complex.selection_zero:no {modelType} selected.`,
          desc:
            'Text to display when user has no {modelType} selected.' +
            'The {modelType} parameter is one of {station, pointcloud, model}, ' +
            'but not to be translated in this string. ' +
            'It is translated separately and interpolated into this string.',
        },
        '=1': {
          text: $localize`:@@demo.complex.selection_one:one {modelType} selected.`,
          desc: 'Text to display when user has one {modelType} selected.',
        },
        '=2': {
          text: $localize`:@@demo.complex.selection_two:two {modelType} selected.`,
          desc: 'Text to display when user has two {modelType} selected.',
        },
        other: {
          text: $localize`:@@demo.complex.selection_other:{count} {modelType} selected.`,
          desc: 'Text to display when user has more than two {modelType} selected.',
        },
      },
    },
  } satisfies TranslationMap;
}
