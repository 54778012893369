<div class="h-full flex flex-col mx-4 items-center justify-center">
  <div class="text-center text-sm">
    <div class="text-lg font-semibold mt-8">
      {{ translations.connectPermissionDenied.title.text }}
    </div>

    <p class="mt-2">{{ translations.connectPermissionDenied.message.text }}</p>

    @switch (currentView()) {
      @case (view.Extension3D) {
        <p class="mt-2">{{ translations.connectPermissionDenied.connect3d.help.text }}</p>
      }
      @default {
        <p class="mt-2">
          {{ translations.connectPermissionDenied.connectExplorer.help.text }}
        </p>

        <button id="view-extensions" class="mt-4" modus-flat-button (click)="viewExtensions()">
          {{ translations.connectPermissionDenied.connectExplorer.settings.button.text }}
        </button>
      }
    }
  </div>
</div>
