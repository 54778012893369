import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  input,
  output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusCheckboxModule, ModusIconModule } from '@trimble-gcs/modus';
import { FileSizePipe } from '@trimble-gcs/ngx-common';
import { ClarityTreeItem, ClarityTreeItemType } from '../clarity.models';

@UntilDestroy()
@Component({
  selector: 'sd-clarity-file-browser-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusCheckboxModule,
    ModusIconModule,
    MatTableModule,
    FileSizePipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './clarity-file-browser-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClarityFileBrowserTableComponent {
  treeItems = input.required<ClarityTreeItem[]>();
  showPrevious = input(false);
  disabled = input<boolean>(false);

  onPreviousClick = output();
  onFolderClick = output<ClarityTreeItem>();
  onSelectionChange = output<ClarityTreeItem[]>();

  dataSource = computed(() => {
    const sortedTreeItems = this.treeItems().sort(
      (a, b) => b.type.localeCompare(a.type) || a.name.localeCompare(b.name),
    );
    const data = this.showPrevious() ? [this.backTreeItem, ...sortedTreeItems] : sortedTreeItems;

    return new MatTableDataSource<ClarityTreeItem>(data);
  });

  private isAllSelected = computed(() => {
    const treeItems = this.treeItems().filter((item) => item.type === ClarityTreeItemType.file);
    return treeItems.length > 0 && treeItems.every((item) => item.selected);
  });

  readonly selectAllControl = new FormControl(false, { nonNullable: true });
  readonly treeItemType = ClarityTreeItemType;
  readonly displayedColumns = ['selected', 'thumbnailUrl', 'name', 'size'];

  private readonly backTreeItem: ClarityTreeItem = {
    id: '',
    type: ClarityTreeItemType.previous,
    name: '...',
    size: 0,
    selected: false,
    worldId: 0,
  };

  constructor() {
    this.createSelectAllEffect();
  }

  getItemId(index: number, item: ClarityTreeItem) {
    return item.id;
  }

  itemClick(item: ClarityTreeItem) {
    if (this.disabled()) return;

    if (item.type === ClarityTreeItemType.previous) {
      this.onPreviousClick.emit();
    } else if (item.type === ClarityTreeItemType.folder) {
      this.onFolderClick.emit(item);
    } else {
      this.onSelectionChange.emit([{ ...item, selected: !item.selected }]);
    }
  }

  toggleAllRows() {
    const selected = !this.isAllSelected();

    const items = this.treeItems()
      .filter((item) => item.type === ClarityTreeItemType.file)
      .map((item) => ({ ...item, selected }));

    this.onSelectionChange.emit(items);
  }

  checkboxKeyDown(treeItem: ClarityTreeItem) {
    treeItem.selected = !treeItem.selected;
    this.onSelectionChange.emit([treeItem]);
  }

  createSelectAllEffect() {
    effect(() => {
      this.selectAllControl.setValue(this.isAllSelected());
      if (this.disabled()) {
        this.selectAllControl.disable();
      } else {
        this.selectAllControl.enable();
      }
    });
  }
}
