<div class="flex flex-col h-full w-full">
  <!-- header -->
  <div mat-dialog-title>
    <div class="flex flex-1 flex-wrap items-center font-bold">Import File(s) From Connect</div>

    <button
      modus-icon-button
      modus-tooltip="Close"
      [disabled]="disableCancel()"
      (click)="cancelClick()"
      class="ml-4"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <mat-dialog-content class="min-h-60 !pb-0 !border-0 text-trimble-gray">
    <div class="w-full relative">
      @if (isLoading()) {
        <div class="w-full absolute">
          <mat-progress-bar class="mds-x-small" [mode]="'indeterminate'"></mat-progress-bar>
        </div>
      }
    </div>

    <!-- project select-->
    <div class="flex items-center mt-2 gap-4">
      <div class="text-gray-8">Connect Project</div>
      <div class="flex items-center">
        {{ selectedProjectName() }}
        <button
          modus-icon-button
          class="mx-1"
          modus-tooltip="Select Connect project"
          [disabled]="disableCancel()"
          (click)="showProjectPicker()"
        >
          <md-icon>more_horizontal</md-icon>
        </button>
      </div>
    </div>

    <!-- files select -->
    <div class="flex flex-1 mt-4 overflow-hidden">
      <sd-connect-file-browser
        [connectRegion]="selectedRegion()"
        [connectProject]="selectedProject()"
        [selectedItems]="selectedItems()"
        [disabled]="disablePicking()"
        (onLoading)="setLoading($event)"
        (onSelectionChange)="selectionChange($event)"
        class="flex flex-1"
      ></sd-connect-file-browser>
    </div>
  </mat-dialog-content>

  <!-- actions -->
  <mat-dialog-actions class="flex items-center text-trimble-gray !mt-1 !mb-2 !border-0">
    <div class="text-sm" [modus-tooltip]="selectedSummaryTooltip()">{{ selectedSummary() }}</div>
    <div class="flex grow justify-end">
      <button modus-button color="secondary" [disabled]="disableCancel()" (click)="cancelClick()">
        Cancel
      </button>
      <button
        modus-flat-button
        color="primary"
        [disabled]="disableNext()"
        (click)="nextClick()"
        class="ml-4"
      >
        Next
      </button>
    </div>
  </mat-dialog-actions>
</div>
